import React from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import ReactExport from "react-export-excel";
import { intToCurrency } from "../../helpers/currencyHelper";
import { getDateString } from "../../helpers/dateHelper";
import Admin from "../../models/admin";
import Driver from "../../models/driver";
import DriverReport, { getDriverCompleteAmounts } from "../../models/driver_report";
import { RootState } from "../../store/reducers/rootReducer";
import SplashScreen from "../SplashScreen";
import ReportView from "./components/ReportView";

type DriversReportsScreenProps = {
  driverId?: string
}

function DriversReportsScreen({ driverId }: DriversReportsScreenProps) {
  const isSingleDriver = driverId !== undefined;
  const driver = useSelector((state: RootState) => driverId === undefined ? null : state.firestore.data.driver as Driver);
  const reports = useSelector((state: RootState) => state.firestore.ordered.reports as DriverReport[]);
  const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);

  useFirestoreConnect(() => driverId === undefined ? [
    {
      collection: "driver_reports",
      storeAs: "reports",
    },
  ] : [
    {
      collection: "drivers",
      doc: driverId,
      storeAs: "driver",
    },
    {
      collection: "driver_reports",
      where: ["driverId", "==", driverId!],
      storeAs: "reports",
    },
  ]);

  if (!isLoaded(reports, admin) || (isSingleDriver && !isLoaded(driver)))
    return <SplashScreen />;

  if (isSingleDriver && isEmpty(driver))
    return <div className="container my-4">Fattorino inesistente.</div>;

  if (!admin.isMainAdmin && (!isSingleDriver || !admin.areaIds!.includes(driver!.areaId)))
    return <div className="container my-4">Non hai accesso alla visualizzazione di questo fattorino.</div>;

  let exportFileName = "Resoconti";
  if (isSingleDriver)
    exportFileName += "_fattorino_" + driverId;
  else
    exportFileName += "_fattorini";

  return <div className="container my-4">
    <div className="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h2>Elenco resoconti {isSingleDriver ? "del fattorino" : "dei fattorini"}</h2>
        {
          isSingleDriver && <h4>{driver!.nominative}</h4>
        }
      </div>
      <div className="d-flex flex-column align-items-end">
        {
          isSingleDriver &&
          <div className="d-flex mb-2">
            <Link to={"/drivers/" + driverId + "/reports/create"} className="btn btn-outline-primary">Genera resoconto</Link>
            <Link to={"/drivers/" + driverId + "/reports"} className="btn btn-outline-secondary ml-2">Visualizza entrate</Link>
          </div>
        }
        <ReportExportExcel
          fileName={exportFileName}
          reports={reports} />
      </div>
    </div>
    <div className="py-1" />
    {reports.length === 0 && "Nessun resoconto ancora creato."}
    {reports.map((report) => <ReportView
      report={report}
      type="driver"
      key={report.id}
      showEntityInfo={!isSingleDriver}
    />)}
  </div>
}


export default DriversReportsScreen;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type ReportExportExcel = {
  reports: DriverReport[],
  fileName: string,
}

function ReportExportExcel({ reports, fileName }: ReportExportExcel) {
  const reportToData = (r: DriverReport) => {
    let completeReport = getDriverCompleteAmounts(r);
    return ({
      date: getDateString(r.datetime),
      id: r.id,
      totalAmount: intToCurrency(completeReport.totalAmount),
      cashDiffAmount: intToCurrency(completeReport.cashDiffAmount),
      driverName: r.driverName,
      driverId: r.driverId,
    });
  };

  return <ExcelFile element={<button className="btn btn-outline-success">Esporta resoconti</button>}
    filename={fileName}>
    <ExcelSheet data={reports.filter((r) => r.useCashToPay).map(reportToData)}
      name={"Resoconti - Contanti usati come compenso"}>
      <ExcelColumn label="CODICE RESOCONTO" value="id" />
      <ExcelColumn label="DATA RESOCONTO" value="date" />
      <ExcelColumn label="NOME" value="driverName" />
      <ExcelColumn label="CODICE FATTORINO" value="driverId" />
      <ExcelColumn label="TOTALE ENTRATE" value="totalAmount" />
      <ExcelColumn label="SOLDI ALLA MANO USATI COME COMPENSO" value="cashDiffAmount" />
    </ExcelSheet>
  </ExcelFile>;
}

/*
function ReportExportExcel({ reports, fileName }: ReportExportExcel) {
  const reportToData = (r: DriverReport) => {
    let completeReport = getDriverCompleteAmounts(r);
    return ({
      date: getDateString(r.datetime),
      id: r.id,
      useCashToPay: r.useCashToPay ? "Si" : "No",
      orderAmount: intToCurrency(r.orderAmount),
      bonusAmount: intToCurrency(r.bonusAmount),
      otherAmount: intToCurrency(r.otherAmount),
      totalAmount: intToCurrency(completeReport.totalAmount),
      cashAmount: intToCurrency(r.cashAmount),
      cashReceivedAmount: intToCurrency(r.cashReceivedAmount),
      cashDiffAmount: intToCurrency(completeReport.cashDiffAmount),
      driverName: r.driverName,
      driverId: r.driverId,
    });
  };

  return <ExcelFile element={<button className="btn btn-outline-success">Esporta resoconti</button>}
    filename={fileName}>
    <ExcelSheet data={reports.map(reportToData)} name={"Resoconti"}>
      <ExcelColumn label="NOME" value="driverName" />
      <ExcelColumn label="CODICE FATTORINO" value="driverId" />
      <ExcelColumn label="DATA RESOCONTO" value="date" />
      <ExcelColumn label="SOLDI ALLA MANO USATI COME COMPENSO" value="useCashToPay" />
      <ExcelColumn label="CODICE RESOCONTO" value="id" />
      <ExcelColumn label="ENTRATE ORDINI" value="orderAmount" />
      <ExcelColumn label="ENTRATE BONUS" value="bonusAmount" />
      <ExcelColumn label="ENTRATE NON CATEGORIZZATE" value="otherAmount" />
      <ExcelColumn label="TOTALE ENTRATE" value="totalAmount" />
      <ExcelColumn label="SOLDI ALLA MANO ACCUMULATI" value="cashAmount" />
      <ExcelColumn label="SOLDI ALLA MANO RICEVUTI" value="cashReceivedAmount" />
      <ExcelColumn label="DIFFERENZA SOLDI ALLA MANO" value="cashDiffAmount" />
    </ExcelSheet>
    <ExcelSheet data={reports.filter((r) => r.useCashToPay).map(reportToData)} name={"Resoconti - Soldi alla mano usati come compenso"}>
      <ExcelColumn label="NOME" value="driverName" />
      <ExcelColumn label="CODICE FATTORINO" value="driverId" />
      <ExcelColumn label="DATA RESOCONTO" value="date" />
      <ExcelColumn label="SOLDI ALLA MANO USATI COME COMPENSO" value="useCashToPay" />
      <ExcelColumn label="CODICE RESOCONTO" value="id" />
      <ExcelColumn label="ENTRATE ORDINI" value="orderAmount" />
      <ExcelColumn label="ENTRATE BONUS" value="bonusAmount" />
      <ExcelColumn label="ENTRATE NON CATEGORIZZATE" value="otherAmount" />
      <ExcelColumn label="TOTALE ENTRATE" value="totalAmount" />
      <ExcelColumn label="SOLDI ALLA MANO ACCUMULATI" value="cashAmount" />
      <ExcelColumn label="SOLDI ALLA MANO RICEVUTI" value="cashReceivedAmount" />
      <ExcelColumn label="DIFFERENZA SOLDI ALLA MANO" value="cashDiffAmount" />
    </ExcelSheet>
  </ExcelFile>;
}
*/

type DriverReportsScreenProps = RouteComponentProps<{ id: string }, any, any>;

function _DriverReportsScreen(props: DriverReportsScreenProps) {
  return <DriversReportsScreen driverId={props.match.params.id} />
}

const DriverReportsScreen = withRouter(_DriverReportsScreen);

export { DriverReportsScreen };