import React, { Component } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import Collapsible from 'react-collapsible';
import './styles/ShiftComponent.css';
import { Link } from 'react-router-dom';
import firebase from '../../../../config/fbConfig'
import { toastr } from 'react-redux-toastr';
import ButtonWithConfirmation from '../../../../components/ButtonWithConfirmation';

class ShiftComponent extends Component {

  render() {
    let { startTime, shift, alsoEmpty, driversData, shiftLength } = this.props;

    let driversCount = 0;

    if (shift?.driversCount) {
      driversCount = shift.driversCount;
    }

    if (!alsoEmpty && driversCount === 0)
      return <div />;

    let availableDrivers = [], occupiedDrivers = [];

    if (driversData && shift) {
      let occupiedDriversIds = shift.occupiedDrivers ?? [];
      availableDrivers = driversData.filter((d) => shift.drivers.includes(d.driverId) && !occupiedDriversIds.includes(d.driverId));
      occupiedDrivers = driversData.filter((d) => shift.drivers.includes(d.driverId) && occupiedDriversIds.includes(d.driverId));
    }

    async function freeDriverShift(driverId) {
      const functions = firebase.functions();
      const freeDriverShiftAsAdmin = functions.httpsCallable("freeDriverShiftAsAdmin");
      try {
        const response = await freeDriverShiftAsAdmin({ driverId, shiftTimestamp: startTime.valueOf() });
        if (response.data.success) {
          toastr.success("Liberazione del fattorino eseguita con successo.");
        }
        else {
          toastr.error("Errore durante la la liberazione del fattorino. " + response.data.error);
        }
      }
      catch (err) {
        console.log(err)
        toastr.error("Errore inaspettato. Riprovare più avanti!");
      }
    }

    async function occupyDriverShift(driverId) {
      const functions = firebase.functions();
      const occupyDriverShiftAsAdmin = functions.httpsCallable("occupyDriverShiftAsAdmin");
      try {
        const response = await occupyDriverShiftAsAdmin({ driverId, shiftTimestamp: startTime.valueOf() });
        if (response.data.success) {
          toastr.success("Occupazione del turno del fattorino eseguita con successo.");
        }
        else {
          if (response.data.reason === "assigned_to_order")
            toastr.error("Errore: Il fattorino è stato assegnato a un ordine.");
          else
            toastr.error("Errore durante la la liberazione del fattorino.");
        }
      }
      catch (err) {
        console.log(err)
        toastr.error("Errore inaspettato. Riprovare più avanti!");
      }
    }

    return (
      <div className="card card-body py-2 my-1">
        <Collapsible transitionTime={200} trigger={
          <div className="d-flex justify-content-between align-items-center" style={driversCount > 0 ? { cursor: "pointer" } : {}}>
            <div>
              {startTime.format("HH:mm")} - {startTime.clone().add(shiftLength, "minutes").format("HH:mm")}
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <div>
                {driversCount === 0 ? "Nessun fattorino" : (driversCount === 1 ? "1 fattorino" : driversCount + " fattorini")}
              </div>
              {driversCount > 0 && <i className="fas fa-chevron-down ml-2 arrow-collapsible" />}
            </div>
          </div>}>

          {(driversCount > 0 && driversData) &&
            <div>
              <div className="mt-3"><b>Totale fattorini: </b> {driversCount}</div>
              <div><b>Totale fattorini disponibili: </b> {shift.availableDriversCount}</div>
              <div className="row">
                {availableDrivers.length > 0 &&
                  <div className="col-12 col-md-6">
                    <div className="mt-2"><b>Fattorini disponibili:</b></div>
                    {availableDrivers.map((d) => {
                      return (
                        <div key={d.driverId} className="d-flex align-items-center">
                          <span className="mr-1">•</span>
                          <Link className="driver-reservation mr-2" to={"/drivers/" + d.driverId}>
                            {d.name ?? "Fattorino " + d.driverId}
                          </Link>
                          <ButtonWithConfirmation className="link-button" style={{ color: "green", fontSize: "0.8rem" }} buttonText="Occupa" onConfirm={() => occupyDriverShift(d.driverId)}
                            modalTitle="Occupa fattorino" modalMessage="Sicuro di voler occupare il fattorino selezionato? Non potrà essere assegnato a un ordine in questo turno." />
                        </div>
                      );
                    })}
                  </div>
                }
                {occupiedDrivers.length > 0 &&
                  <div className="col-12 col-md-6">
                    <div className="mt-2"><b>Fattorini assegnati ad un ordine:</b></div>
                    {occupiedDrivers.map((d) => {
                      return (
                        <div key={d.driverId} className="d-flex align-items-center">
                          <span className="mr-1">•</span>
                          <Link className="driver-reservation mr-2" to={"/drivers/" + d.driverId}>
                            {d.name ?? "Fattorino " + d.driverId}
                          </Link>
                          <ButtonWithConfirmation className="link-button" style={{ color: "red", fontSize: "0.8rem" }} buttonText="Libera" onConfirm={() => freeDriverShift(d.driverId)}
                            modalTitle="Libera fattorino" modalMessage="Sicuro di voler liberare il fattorino selezionato? Potrà essere assegnato ad altri ordini." />
                        </div>
                      );
                    })}
                  </div>
                }
              </div>
            </div>

          }
        </Collapsible>
      </div>
    );
  }
}


const mapStateToProps = (state, props) => {
  return {
    driversData: state.firestore.ordered['driversData_' + props.startTime.valueOf().toString()],
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    if (props.shift) {
      let milliseconds = props.startTime.valueOf().toString();
      return [
        {
          collection: 'areas',
          doc: props.areaId,
          subcollections: [{
            collection: 'shifts',
            doc: milliseconds,
            subcollections: [{ collection: "driversData" }]
          }],
          storeAs: 'driversData_' + milliseconds,
        },
      ];

    }
    return [];
  })
)(ShiftComponent)