import firebase from '../config/fbConfig';

export type SupplierAmounts = {
  totalOrderAmount: number;
  orderAmountNoIva: number;
  orderAmount: number;
  otherAmount: number;
  cashAmount: number;
  cashReceivedAmount: number;
}

type SupplierReport = SupplierAmounts & {
  id: string;
  datetime: firebase.firestore.Timestamp;
  supplierId: string;
  supplierName: string;
  useCashToPay: boolean;
}

export type SupplierCompleteAmounts = SupplierAmounts & {
  totalAmount: number;
  cashDiffAmount: number;
}

export function getSupplierCompleteAmounts(amounts: SupplierAmounts): SupplierCompleteAmounts {
  const totalAmount = amounts.orderAmount + amounts.otherAmount;
  const cashDiffAmount = amounts.cashAmount - amounts.cashReceivedAmount;
  return {
    ...amounts,
    totalAmount,
    cashDiffAmount,
  }
}

export default SupplierReport;