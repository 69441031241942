export const signIn = (credentials) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const auth = firebase.auth();

    try {
      let user = (await auth.signInWithEmailAndPassword(credentials.email, credentials.password)).user;

      let token = await user.getIdToken();

      if(token.claims.admin === true && token.claims.adminId !== null) {
        dispatch({ type: 'LOGIN_SUCCESS', supplierId: token.claims.adminId });
      }
      else {
        dispatch({ type: 'LOGIN_NOT_ADMIN' });
      }
    }
    catch (err) {
      console.log(err);
      dispatch({ type: 'LOGIN_ERROR', err });
    }

  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signOut().then(() => {
      dispatch({ type: 'SIGNOUT_SUCCESS' })
    });
  }
}

export const provideAdminId = (adminId) => {
  return (dispatch) => {
    dispatch({ type: 'ADMIN_ID_PROVIDER', adminId });
  }
}