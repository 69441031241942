import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CategoryForm from '../components/CategoryForm';
import firebase from '../../../config/fbConfig'
import { toastr } from 'react-redux-toastr';
import './styles/SupplierEditCategoryScreen.css'
import SplashScreen from '../../SplashScreen';
import ButtonWithConfirmation from '../../../components/ButtonWithConfirmation';

class SupplierEditCategoryScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
      loaded: false,
      category: null,
    }

    this.loadCategory();
  }

  loadCategory = async () => {
    let firestore = firebase.firestore();
    let categoryId = this.props.match.params.categoryId;
    let snap = await firestore.collection('supplier_categories').doc(categoryId).get();
    if (snap.exists) {
      this.setState({
        category: snap.data(),
        loaded: true,
      })
    } else {
      this.setState({
        loaded: true,
      })
    }
  }

  onSave = async (data) => {
    this.setState({
      isSaving: true
    });

    const firestore = firebase.firestore();
    const storage = firebase.storage();
    let categoryId = this.props.match.params.categoryId;

    const documentRef = firestore.collection('supplier_categories').doc(categoryId);
    const storageRef = storage.ref().child('supplier_categories/' + categoryId);

    try {
      let imageProps = {};
      if (data.imageFile !== null) {
        await storageRef.child(data.imageFile.name).put(data.imageFile);
        let imageUrl = await storageRef.child(data.imageFile.name).getDownloadURL();
        imageProps = {
          imageUrl: imageUrl,
        }
      }

      await documentRef.update({
        name: data.name,
        tags: data.tags,
        ...imageProps
      });

      toastr.success("Categoria aggiornata con successo!");
      this.props.history.push("/suppliers");
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      this.setState({
        isSaving: false,
      })
      console.log(err);
    }
  }

  onDelete = async () => {
    if (this.state.isSaving)
      return;

    this.setState({
      isSaving: true
    });

    const firestore = firebase.firestore();
    let categoryId = this.props.match.params.categoryId;

    const documentRef = firestore.collection('supplier_categories').doc(categoryId);

    try {
      let suppliersInCategory = await firestore.collection('suppliers').where('category', '==', categoryId).get();
      if (suppliersInCategory.docs.length > 0) {
        toastr.error("Impossibile effettuare l'operazione. Dei fornitori fanno ancora parte della categoria. ")
        this.setState({
          isSaving: false,
        })
        return;
      }

      let requestsInCategory = await firestore.collection('supplier_requests').where('category', '==', categoryId).where('state', '==', "PENDING").get();
      if (requestsInCategory.docs.length > 0) {
        toastr.error("Impossibile effettuare l'operazione. Delle richieste di collaborazione includono fornitori di questa categoria.")
        this.setState({
          isSaving: false,
        })
        return;
      }

      await documentRef.delete();

      toastr.success("Categoria eliminata con successo!");
      this.props.history.push("/suppliers");
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      this.setState({
        isSaving: false,
      })
      console.log(err);
    }
  }

  render() {
    if (!this.state.loaded)
      return <SplashScreen />;

    if (this.state.category === null)
      return <div className="container my-4">Categoria inesistente.</div>

    return <div className="container my-4">
      <CategoryForm mode="edit" category={this.state.category} onSave={this.onSave} isSaving={this.state.isSaving} />
      <ButtonWithConfirmation className="delete-category" onConfirm={this.onDelete} buttonText="Elimina categoria"
        modalTitle="Elimina categoria" modalMessage="Sicuro di voler eliminare la categoria? L'azione è irreversibile." />
    </div>;
  }
}

export default withRouter(SupplierEditCategoryScreen);