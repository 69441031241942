import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { intToCurrency } from "../../../helpers/currencyHelper";
import { getDateString } from "../../../helpers/dateHelper";
import DriverReport, { DriverAmounts, DriverCompleteAmounts, getDriverCompleteAmounts } from "../../../models/driver_report";
import SupplierReport, { getSupplierCompleteAmounts, SupplierAmounts, SupplierCompleteAmounts } from "../../../models/supplier_report";

type ReportViewProps = RouteComponentProps<any, any, any> & {
    report: DriverReport | SupplierReport;
    showEntityInfo: boolean;
    type: "driver" | "supplier"
}

function ReportView({ history, report, type, showEntityInfo }: ReportViewProps) {
    let completeAmounts: DriverCompleteAmounts | SupplierCompleteAmounts;
    let reportDetailsUrl: string;
    let name: string;
    if (type === "driver") {
        completeAmounts = getDriverCompleteAmounts(report as DriverAmounts);
        reportDetailsUrl = "/drivers/" + (report as DriverReport).driverId + "/reports/" + report.id;
        name = (report as DriverReport).driverName;
    }
    else {
        completeAmounts = getSupplierCompleteAmounts(report as SupplierAmounts);
        reportDetailsUrl = "/suppliers/" + (report as SupplierReport).supplierId + "/reports/" + report.id;
        name = (report as SupplierReport).supplierName;
    }

    let infoClass = showEntityInfo ? "col-lg-3" : "col-lg-4";
    return <div className="card card-body my-2" style={{ cursor: "pointer" }} onClick={() => history.push(reportDetailsUrl)}>
        <div className="row align-items-center">
            {
                showEntityInfo &&
                <div className={infoClass + " col-6"}>
                    <div>{name}</div>
                </div>
            }
            <div className={infoClass + " col-6 d-flex align-items-center"}>
                <div>{getDateString(report.datetime)}</div>
            </div>
            <div className={infoClass + " col-6"}>
                <div style={{ fontSize: 18, color: "green" }}>+{intToCurrency(completeAmounts.totalAmount)}</div>
            </div>
            <div className={infoClass + " col-6"}>
                {report.useCashToPay && "Contanti usati come compenso"}
            </div>
        </div>
    </div>
}

export default withRouter(ReportView);