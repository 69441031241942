import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import NumberFormat from 'react-number-format';
import { doubleToCurrency, doubleToPercentage } from '../../../../helpers/currencyHelper';

type ChangeSettingsFieldModalProps = {
  initialValue: number;
  defaultSettings: number;
  initialUseDefaultSettings: boolean;
  show: boolean;
  close: () => void;
  supplierId: string;
  fieldId: string;
  title: string;
  type: "currency" | "percentage"
}

function ChangeSettingsFieldModal({ initialValue, defaultSettings, initialUseDefaultSettings, show, close, supplierId, fieldId, title, type }: ChangeSettingsFieldModalProps) {
  const [value, setValue] = useState<number | undefined>(initialValue ?? 0);
  const [useDefaultSettings, setUseDefaultSettings] = useState(initialUseDefaultSettings);

  useEffect(() => {
    if (show) {
      setValue(initialValue);
      setUseDefaultSettings(initialUseDefaultSettings);
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    try {
      await firestore.collection("suppliers").doc(supplierId).update({
        [fieldId]: useDefaultSettings ? null : value,
      });

      toastr.success("Impostazione cambiata con successo!", "");
    }
    catch (err) {
      toastr.error("Errore inaspettato!", "");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="custom-control custom-radio mt-3" onClick={() => setUseDefaultSettings(true)}>
          <input type="radio" id="useDefaultSettings" className="custom-control-input" checked={useDefaultSettings} onChange={(e) => setUseDefaultSettings(e.target.checked)} />
          <label className="custom-control-label" htmlFor="useDefaultSettings">
            Usa impostazioni di default ({type === "percentage" ? doubleToPercentage(defaultSettings) : doubleToCurrency(defaultSettings)})
          </label>
        </div>
        <div className="custom-control custom-radio mt-3" onClick={() => setUseDefaultSettings(false)}>
          <input type="radio" id="usePersonalSettings" className="custom-control-input" checked={!useDefaultSettings} onChange={(e) => setUseDefaultSettings(!e.target.checked)} />
          <label className="custom-control-label" htmlFor="usePersonalSettings">
            Impostazioni personalizzate
            {
              !useDefaultSettings &&
              <div className="mt-2 d-flex align-items-center">
                <div className="mr-2">Valore: </div>
                <NumberFormat
                  decimalScale={2}
                  allowNegative={false}
                  thousandSeparator={false}
                  decimalSeparator=","
                  suffix={type === "percentage" ? "%" : "€"}
                  fixedDecimalScale={true}
                  className="form-control"
                  value={value === undefined ? undefined : (type === "percentage" ? (value * 100.) : value)}
                  isAllowed={(values) => {
                    if (type !== "percentage")
                      return true;
                    const { floatValue } = values;

                    return floatValue === undefined || (floatValue > 0 && floatValue <= 100);
                  }}
                  onValueChange={({ floatValue }) => {
                    if (floatValue === undefined)
                      setValue(floatValue);
                    else if (floatValue !== value)
                      setValue(type === "percentage" ? (floatValue / 100.) : floatValue)
                  }} required />
              </div>
            }
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={save}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeSettingsFieldModal;
