import React from 'react'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { doubleToCurrency, doubleToPercentage } from '../../helpers/currencyHelper';
import { useState } from 'react';
import firebase from 'firebase/app';
import { toastr } from 'react-redux-toastr';
import { RootState } from '../../store/reducers/rootReducer';
import DiscountCode, { WeekdayNumber } from '../../models/discount_code';
import DiscountCodeCustomer from '../../models/discount_code_customer';
import Supplier from '../../models/supplier';
import Area from '../../models/area';
import { capitalize } from '../../helpers/stringHelper';
import Admin from '../../models/admin';
import ButtonWithConfirmation from '../../components/ButtonWithConfirmation';
import SplashScreen from '../SplashScreen';

type DiscountDetailsScreenParams = {
  codeId: string
}

function DiscountDetailsScreen(props: RouteComponentProps<DiscountDetailsScreenParams, any, any>) {
  const [stateLoading, setStateLoading] = useState(false);

  const discountCode: DiscountCode = useSelector((state: RootState) => state.firestore.data.discountCodes?.[props.match.params.codeId]);
  const discountCodeCustomers: DiscountCodeCustomer[] = useSelector((state: RootState) => state.firestore.ordered.discountCodeCustomers);

  useFirestoreConnect([
    {
      collection: "discountCodes",
      doc: props.match.params.codeId
    },
    {
      collection: "discountCodes",
      doc: props.match.params.codeId,
      subcollections: [{ collection: "discountCodeCustomers" }],
      storeAs: "discountCodeCustomers"
    },
    ...discountCode?.type === "SINGLE_SUPPLIER" ? [
      {
        collection: "suppliers",
        doc: discountCode.supplierId
      },
    ] : [],
    ...discountCode?.type === "SINGLE_AREA" ? [
      {
        collection: "areas",
        doc: discountCode.areaId
      },
    ] : []
  ]);

  const area: Area | undefined = useSelector((state: RootState) => state.firestore.data.areas?.[discountCode?.areaId]);
  const supplier: Supplier | undefined = useSelector((state: RootState) => state.firestore.data.suppliers?.[discountCode?.supplierId]);
  const admin: Admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);

  if (!isLoaded(discountCode) || !isLoaded(discountCodeCustomers))
    return <SplashScreen />;

  if (isEmpty(discountCode))
    return <div className="my-4 container">Sconto inesistente</div>

  async function toggleActive(active: boolean) {
    const firestore = firebase.firestore();

    setStateLoading(true);
    try {
      await firestore.collection("discountCodes").doc(discountCode.id).update({
        active: active
      });

      toastr.success("", "Stato dello sconto aggiornato con successo!");
    }
    catch (err) {
      console.log(err);
      toastr.error("", "Errore inaspettato!");
    }
    finally {
      setStateLoading(false);
    }
  }


  async function deleteDiscount() {
    const firestore = firebase.firestore();
    setStateLoading(true);
    try {
      await firestore.collection("discountCodes").doc(discountCode.id).update({
        deleted: true
      });

      toastr.success("", "Sconto eliminato con successo!");
    }
    catch (err) {
      console.log(err);
      toastr.error("", "Errore inaspettato!");
    }
    finally {
      setStateLoading(false);
    }
  }

  let stateWidget;
  if (discountCode.deleted === true) {
    stateWidget = <div className="my-2"><i className="fas fa-circle text-danger fa-xs" /> Lo sconto è stato eliminato</div>;
  }
  else if (discountCode.active === true) {
    stateWidget = <div className="my-2"><i className="fas fa-circle text-success fa-xs" /> Lo sconto è attualmente abilitato</div>;
  }
  else {
    stateWidget = <div className="my-2"><i className="fas fa-circle text-warning fa-xs" /> Lo sconto è attualmente disabilitato</div>;
  }

  let discountCodeCustomersWidget;

  if (discountCode.type !== "SINGLE_CUSTOMER" && discountCode.type !== "REWARD" && discountCode.type !== "BRING_A_FRIEND") {
    let filteredCustomers = discountCodeCustomers.filter((c) => c.used === true);
    discountCodeCustomersWidget = <div>
      <h5 className="mt-3 mb-2">Clienti che hanno usato lo sconto</h5>
      {isEmpty(filteredCustomers) && "Nessun cliente ha usato lo sconto."}
      {filteredCustomers.map((customer) => {
        return (
          <div className="card my-2" key={customer.customerId}>
            <div className="card-body d-flex justify-content-between flex-wrap">
              <Link to={"/customers/" + customer.customerId} className="text-dark">
                {customer.customerId}
              </Link>
              <div>
                Sconto <b>usato </b> (<Link to={"/orders/" + customer.orderId}>Vai all&apos;ordine</Link>)
              </div>
            </div>
          </div>
        )
      })}
    </div>
  }
  else if (discountCode.type === "REWARD") {
    let filteredCustomers = discountCodeCustomers.filter((c) => c.used === false);
    discountCodeCustomersWidget = <div>
      <h5 className="mt-3 mb-0">Clienti che hanno acquistato la ricompensa</h5>
      <div className="mb-2" style={{ fontSize: "15px", color: "grey" }}>Include solo i clienti che devono ancora utilizzarla</div>
      {filteredCustomers.length === 0 && "Al momento nessun cliente ha acquistato la ricompensa."}
      {filteredCustomers.map((customer) => {
        return (
          <div className="card my-2" key={customer.customerId}>
            <div className="card-body d-flex justify-content-between flex-wrap">
              <Link to={"/customers/" + customer.customerId} className="text-dark">
                {customer.customerId}
              </Link>
            </div>
          </div>
        )
      })}
    </div>
  }
  else {
    discountCodeCustomersWidget = <div>
      <h5 className="mt-3 mb-2">Clienti che possono usare lo sconto</h5>
      {isEmpty(discountCodeCustomers) && "Al momento nessun cliente può usare lo sconto."}
      {discountCodeCustomers.map((customer) => {
        return (
          <div className="card my-2" key={customer.customerId}>
            <div className="card-body d-flex justify-content-between flex-wrap">
              <Link to={"/customers/" + customer.customerId} className="text-dark">
                {customer.customerId}
              </Link>
              {
                customer.used === true ?
                  <div>
                    Sconto <b>usato </b> (<Link to={"/orders/" + customer.orderId}>Vai all&apos;ordine</Link>)
                  </div> :
                  <div>Sconto <b>non usato</b> </div>
              }
            </div>
          </div>
        )
      })}
    </div>
  }

  let weekdays = moment.weekdays(true);
  let weekdaysString = "";
  if (discountCode.isPeriodic === true && discountCode.weekdayValidity !== undefined)
    for (let i = 1; i <= 7; i++) {
      if (discountCode.weekdayValidity[i as WeekdayNumber])
        weekdaysString += weekdays[i - 1] + ", ";
    }

  weekdaysString = capitalize(weekdaysString);
  weekdaysString = weekdaysString.slice(0, weekdaysString.length - 2);

  let canEditDiscount = admin.isMainAdmin
    || (discountCode.type === "SINGLE_AREA" && admin.areaIds!.includes(discountCode.areaId))
    || (discountCode.type === "SINGLE_SUPPLIER" && supplier !== undefined && admin.areaIds!.includes(supplier.areaId))
    || discountCode.type === "SINGLE_CUSTOMER";

  return <div className="my-4 container">
    <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div>
        <h4>Sconto {discountCode.code}</h4>
        {stateWidget}
      </div>
      {
        canEditDiscount &&
        <div className="d-flex">
          {discountCode.deleted !== true && discountCode.active === false &&
            <ButtonWithConfirmation className="btn btn-success" buttonText="Abilita" disabled={stateLoading} onConfirm={() => toggleActive(true)}
              modalTitle="Abilita sconto" modalMessage="Sicuro di voler abilitare lo sconto? Gli utenti potranno iniziare ad utilizzarlo." />}
          {discountCode.deleted !== true && discountCode.active === true &&
            <ButtonWithConfirmation className="btn btn-warning" buttonText="Disabilita" disabled={stateLoading} onConfirm={() => toggleActive(false)}
              modalTitle="Disabilita sconto" modalMessage="Sicuro di voler disabilitare lo sconto? Gli utenti non potranno più utilizzarlo." />}
          {discountCode.deleted !== true && discountCode.type !== "REWARD" &&
            <ButtonWithConfirmation className="btn btn-outline-danger ml-2" buttonText="Elimina" disabled={stateLoading} onConfirm={deleteDiscount}
              modalTitle="Elimina sconto" modalMessage="Sicuro di voler eliminare lo sconto? L'azione è irreversibile." />}
        </div>

      }
    </div>
    <div className="card card-body">
      <div className="my-1">
        {
          discountCode.hasFixedAmount ?
            (<span>Sconto di <b>{doubleToCurrency(discountCode.amount)} </b></span>) :
            (<span>Sconto del <b>{doubleToPercentage(discountCode.percentage)} </b></span>)
        }<b>{discountCode.hasFreeDelivery ? " + spedizione gratuita" : ""}</b>
      </div>
      <div className="my-1">
        Codice sconto: <b>{discountCode.code}</b>
      </div>
      <div className="d-flex align-items-center my-1">
        <i className="fas fa-users" style={{ width: 25 }}></i>
        <div className="ml-2">
          {
            discountCode.type === "REWARD" &&
            <>Ricompensa punti</>
          }
          {
            discountCode.type === "ALL_CUSTOMERS" &&
            <>Tutti i clienti</>
          }
          {
            discountCode.type === "SINGLE_SUPPLIER" && supplier !== undefined &&
            <>Fornitore {supplier.name}</>
          }
          {
            discountCode.type === "SINGLE_AREA" && area !== undefined &&
            <>Area {area.name}</>
          }
          {
            discountCode.type === "SINGLE_CUSTOMER" &&
            <>Clienti singoli</>
          }
          {
            discountCode.type === "BRING_A_FRIEND" &&
            <>Codice "Presenta un amico" per aver presentato l'utente con ID <b>{discountCode.bringAFriendData!.friendId}</b></>
          }
        </div>
      </div>
      {
        discountCode.type !== "REWARD" && discountCode.type !== "BRING_A_FRIEND" &&
        <>

          <div className="d-flex align-items-center my-1">
            <i className="fas fa-calendar-day" style={{ width: 25 }}></i>
            <div className="ml-2">
              Dal {moment(discountCode.startDate?.toMillis()).format("D MMMM YYYY")}
              {discountCode.endDate ? (" al " + moment(discountCode.endDate.toMillis()).format("D MMMM YYYY")) : ""}
            </div>
          </div>
          {
            discountCode.isPeriodic === true &&
            <div className="d-flex align-items-center my-1">
              <i className="fas fa-calendar-alt" style={{ width: 25 }}></i>
              <div className="ml-2">
                {weekdaysString}
              </div>
            </div>
          }
          <div className="d-flex align-items-center my-1">
            <i className="fas fa-clock" style={{ width: 25 }}></i>
            <div className="ml-2">
              {discountCode.startTime === "00:00" && discountCode.endTime === "00:00" ? "Tutto il giorno" :
                ("Dalle " + discountCode.startTime + " alle " + discountCode.endTime)}
            </div>
          </div>
        </>
      }
      {
        discountCode.type === "REWARD" &&
        <div className="d-flex align-items-center my-1">
          <i className="fas fa-gem" style={{ width: 25 }}></i>
          <div className="ml-2">{discountCode.pointsThreshold} punti</div>
        </div>
      }
      {
        discountCode.isPromo === true && <>
          <b className="mt-2">Promozione associata</b>
          <div className="small mb-1">Lo sconto è associato a una promozione</div>
          <div>Titolo</div>
          <div style={{ fontSize: "14px", color: "grey" }}>{discountCode.promoTitle}</div>
          {
            discountCode.promoMessage && discountCode.promoMessage.length > 0 && <>
              <div>Messaggio</div>
              <div style={{ fontSize: "14px", color: "grey" }}>{discountCode.promoMessage}</div>
            </>
          }
        </>
      }
      {
        discountCode.type === "BRING_A_FRIEND" && <>
          <div className="mt-1" style={{ color: "grey" }}>
            {
              discountCode.bringAFriendData!.status === "PENDING" ?
                "Codice sconto non ancora utilizzabile in quanto l'utente presentato non ha ancora effettuato il suo primo ordine" :
                "Codice sconto utilizzabile in quanto l'utente presentato ha effettuato il suo primo ordine"
            }
          </div>
        </>
      }
    </div>

    {discountCodeCustomersWidget}
  </div>
}

export default withRouter(DiscountDetailsScreen);