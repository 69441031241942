import authReducer from './authReducer'
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import { reducer as toastr } from 'react-redux-toastr';
import orderReducer from './orderReducer';
import serviceWorkerReducer from './serviceWorkerReducer';

type AuthState = {
  authError: string | null;
  adminId: string | null;
}

const rootReducer = combineReducers({
  auth: authReducer as (state: AuthState | undefined, action: any) => AuthState,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  order: orderReducer,
  serviceWorker: serviceWorkerReducer,
  toastr: toastr
});

// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer

// the key name will be the data property on the state object