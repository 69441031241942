import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';

type ChangeCashPaymentOrdersModalProps = {
  initialCanAcceptOrdersWithCashPayment: boolean;
  show: boolean;
  close: () => void;
  driverId: string;
}

function ChangeCashPaymentOrdersModal({ initialCanAcceptOrdersWithCashPayment, show, close, driverId }: ChangeCashPaymentOrdersModalProps) {
  const [canAcceptOrdersWithCashPayment, setCanAcceptOrdersWithCashPayment] = useState(initialCanAcceptOrdersWithCashPayment);

  useEffect(() => {
    if (show) {
      setCanAcceptOrdersWithCashPayment(initialCanAcceptOrdersWithCashPayment);
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    try {
      await firestore.collection("drivers").doc(driverId).update({
        canAcceptOrdersWithCashPayment,
      });

      toastr.success("", "Impostazione cambiata con successo!");
    }
    catch (err) {
      toastr.error("", "Errore inaspettato!");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Consegna di ordini con pagamenti in contanti</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id="canAcceptOrdersWithCashPayment"
            checked={canAcceptOrdersWithCashPayment} onChange={(e) => setCanAcceptOrdersWithCashPayment(e.target.checked)} />
          <label className="custom-control-label" htmlFor="canAcceptOrdersWithCashPayment">Il fattorino può accettare anche ordini con pagamenti in contanti </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={save}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeCashPaymentOrdersModal;
