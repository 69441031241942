import firebase from '../config/fbConfig';

export type DriverAmounts = {
  orderAmount: number;
  bonusAmount: number;
  otherAmount: number;
  cashAmount: number;
  cashReceivedAmount: number;
}

type DriverReport = DriverAmounts & {
  id: string;
  datetime: firebase.firestore.Timestamp;
  driverId: string;
  driverName: string;
  useCashToPay: boolean;
}

export type DriverCompleteAmounts = DriverAmounts & {
  totalAmount: number;
  cashDiffAmount: number;
}

export function getDriverCompleteAmounts(amounts: DriverAmounts): DriverCompleteAmounts {
  const totalAmount = amounts.orderAmount + amounts.bonusAmount + amounts.otherAmount;
  const cashDiffAmount = amounts.cashAmount - amounts.cashReceivedAmount;
  return {
    ...amounts,
    totalAmount,
    cashDiffAmount,
  }
}

export default DriverReport;