import React from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Admin from '../../models/admin';
import { RootState } from '../../store/reducers/rootReducer';
import Area from '../../models/area';
import SplashScreen from '../SplashScreen';

function AreasDashboardScreen() {
  const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);

  useFirestoreConnect([{
    collection: "areas",
    ...!admin.isMainAdmin ? {
      where: ["id", "in", admin.areaIds]
    } : {}
  }]);

  const areas = useSelector((state: RootState) => state.firestore.ordered.areas as Area[]);

  if (!isLoaded(areas))
    return <SplashScreen />;

  return <div className="my-3 container">
    <div className="d-flex justify-content-between align-items-center mb-3" style={{ maxWidth: "400px" }}>
      <h4>Gestione aree</h4>
      {
        admin.isMainAdmin === true &&
        <div><Link className="btn btn-outline-primary" to="areas/edit">Aggiungi area</Link></div>
      }
    </div>
    {areas.map((area) =>
      <div key={area.id} className="card card-body col-12 col-md-6 col-lg-4 py-2 mb-1">
        <Link to={{ pathname: "/areas/edit", state: { area: area } }}>{area.name}</Link>
      </div>
    )}
  </div>
}

export default AreasDashboardScreen;