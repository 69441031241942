import React from 'react';

type ClickableImageProps = {
  url: string;
  style?: React.CSSProperties;
}

function ClickableImage({ url, style }: ClickableImageProps) {
  return <img src={url} style={{ cursor: "pointer", ...style }} onClick={() => {
    let win = window.open(url, '_blank');
    win?.focus();
  }} />;
}

export default ClickableImage;