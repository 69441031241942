
import firebase from "firebase";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { toastr } from "react-redux-toastr";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Checkbox from "../../components/Checkbox";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import IntervalSelection, { Interval } from "../../components/IntervalSelection";
import Admin from "../../models/admin";
import Driver from "../../models/driver";
import Movement from "../../models/movement";
import Settings from "../../models/settings";
import Supplier from "../../models/supplier";
import { deleteMovement } from "../../services/firestore_service";
import { RootState } from "../../store/reducers/rootReducer";
import SplashScreen from "../SplashScreen";
import AmountsCard from "./components/AmountsCard";
import EditMovementModal from "./components/EditMovementModal";
import MovementView from "./components/MovementView";

type ReportDashboardScreenProps = RouteComponentProps<{ id: string }, any, any> & {
  type: "driver" | "supplier"
}

function ReportDashboardScreen(props: ReportDashboardScreenProps) {
  const [interval, setInterval] = useState<Interval>({
    fromDate: moment(moment().subtract(1, "month").format("YYYY-MM-DD")),
    toDate: moment(moment().format("YYYY-MM-DD")),
  });

  const entity = useSelector((state: RootState) => state.firestore.data[props.type] as Supplier | Driver);
  const movements = useSelector((state: RootState) => state.firestore.ordered.movements as Movement[]);
  const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);
  const settings = useSelector((state: RootState) => state.firestore.data.settings as Settings);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingMovement, setEditingModal] = useState<Movement | undefined>(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingMovementId, setDeletingMovementId] = useState<string | null>(null);
  const [showGeneralReport, setShowGeneralReport] = useState(false);

  useFirestoreConnect([
    {
      collection: props.type === "driver" ? "drivers" : "suppliers",
      doc: props.match.params.id,
      storeAs: props.type,
    },
    {
      collection: props.type === "driver" ? "drivers" : "suppliers",
      doc: props.match.params.id,
      subcollections: [{
        collection: "movements",
        where: [
          ["date", "<", firebase.firestore.Timestamp.fromMillis(moment(interval.toDate).add(1, "days").valueOf())],
          ["date", ">=", firebase.firestore.Timestamp.fromMillis(interval.fromDate.valueOf())],
        ],
        orderBy: ["date", "desc"],
      }],
      storeAs: "movements",
    },
    {
      collection: "settings",
      doc: "settings",
      storeAs: "settings",
    }
  ]);

  if (!isLoaded(entity, movements, admin, settings))
    return <SplashScreen />;

  if (isEmpty(entity))
    return <div className="container my-4">{props.type === "driver" ? "Fattorino" : "Fornitore"} inesistente.</div>;

  if (!admin.isMainAdmin && !admin.areaIds!.includes(entity.areaId))
    return <div className="container my-4">Non hai accesso alla visualizzazione di questo {props.type === "driver" ? "fattorino" : "fornitore"}.</div>;

  const basePath = "/" + props.type + "s/" + props.match.params.id + "/";
  const driver = entity as Driver;
  const supplier = entity as Supplier;

  let supplierPercentage: number | null = null;
  if (props.type === "supplier")
    supplierPercentage = supplier.supplierPercentage ?? settings.supplierPercentage;

  return <div className="container my-4">
    <div className="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h2>Entrate del {props.type === "driver" ? "fattorino" : "fornitore"}</h2>
        <h4>{props.type === "driver" ? driver.nominative : supplier.name}</h4>
      </div>
      <div className="d-flex">
        <Link to={basePath + "reports/create"} className="btn btn-outline-primary">Genera resoconto</Link>
        <Link to={basePath + "reports/list"} className="btn btn-outline-secondary ml-2">Visualizza resoconti</Link>
      </div>
    </div>
    <Checkbox checked={showGeneralReport} onChange={setShowGeneralReport} id="general-report-checkbox"
      title="Mostra le entrate totali" />
    <div className="my-2" />
    <AmountsCard amounts={showGeneralReport ? entity.totalAmounts : entity.amounts}
      entityType={props.type}
      showCash={props.type === "driver" || supplier.canUseOwnDrivers === true || supplier.responsibleForCash === true} />
    <div className="mt-4 d-flex align-items-center">
      <h4 className="mb-0">Movimenti</h4>
      <button className="btn btn-sm btn-primary ml-2" style={{ fontSize: 20, height: 38, width: 38 }} onClick={() => {
        setEditingModal(undefined);
        setShowEditModal(true);
      }}>+</button>
    </div>
    <IntervalSelection interval={interval} setInterval={setInterval} />
    {movements.length === 0 && "Nessun movimento nel periodo selezionato"}
    {movements.map((movement) => <MovementView
      movement={movement}
      key={movement.id}
      action={
        <div className="d-flex justify-content-end" style={{ width: 100 }}>
          <i className="fas fa-pen highlight-on-hover" onClick={() => {
            setEditingModal(movement);
            setShowEditModal(true);
          }} />
          <i className="fas fa-trash highlight-on-hover ml-4" onClick={() => {
            setDeletingMovementId(movement.id);
            setShowDeleteModal(true);
          }} />
        </div>
      }
    />)}
    <EditMovementModal
      entityId={props.match.params.id}
      entityType={props.type}
      show={showEditModal}
      movement={editingMovement}
      initialSupplierPercentage={supplierPercentage!}
      onClose={() => setShowEditModal(false)} />
    <ConfirmationDialog modalMessage="Sicuro di voler eliminare questo movimento?"
      modalTitle="Elimina movimento"
      onCancel={() => setShowDeleteModal(false)}
      show={showDeleteModal}
      onConfirm={async () => {
        setShowDeleteModal(false);
        try {
          await deleteMovement(props.match.params.id, props.type, deletingMovementId!);
          toastr.success("", "Movimento eliminato con successo!");
        }
        catch (err) {
          console.log(err);
          toastr.error("", "Errore inaspettato!");
        }
        finally {
          setDeletingMovementId(null);
        }
      }} />
  </div>

}

export default withRouter(ReportDashboardScreen);