import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import Admin from '../../models/admin';
import { RootState } from '../../store/reducers/rootReducer';
import Area from '../../models/area';
import CustomerList from './components/CustomerList';

function CustomerAreaScreen(props: RouteComponentProps<{ areaId: string }, any, any>) {
    const [searchBar, setSearchBar] = useState("");

    useFirestoreConnect([
        {
            collection: 'areas',
            doc: props.match.params.areaId,
            storeAs: 'area',
        },
    ]);

    const area: Area = useSelector((state: RootState) => state.firestore.data.area);
    const admin: Admin = useSelector((state: RootState) => state.firestore.data.currentAdmin);

    if (!isLoaded(area))
        return <div />;

    if (!admin.isMainAdmin && !admin.areaIds!.includes(area.id))
        return <div className="container my-4">Non hai accesso alla visualizzazione di questa pagina.</div>;

    return (
        <div className="my-4 container">
            <h4 className="my-auto">Clienti</h4>
            <h6 className="mt-0" style={{ color: "#777777" }}>{area.name}</h6>
            <div className="d-flex justify-content-between flex-wrap my-3">
                <div className="form-inline">
                    <div className="form-group">
                        <label htmlFor="searchBar">Cerca per email</label>
                        <input type="text" id="searchBar" className="form-control mx-sm-3" value={searchBar} onChange={(e) => setSearchBar(e.target.value)} />
                    </div>
                </div>
            </div>
            <CustomerList searchBar={searchBar} areaId={props.match.params.areaId} />
        </div>
    );
}
export default withRouter(CustomerAreaScreen);