import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect, WhereOptions } from "react-redux-firebase";
import { Link } from "react-router-dom";
import Admin from "../../../models/admin";
import Driver from "../../../models/driver";
import { getDriverCompleteAmounts } from "../../../models/driver_report";
import { RootState } from "../../../store/reducers/rootReducer";
import { intToCurrency } from "../../../helpers/currencyHelper";
import { getDateString } from "../../../helpers/dateHelper";
import SplashScreen from "../../SplashScreen";

type DriverListProps = {
    areaId?: string;
}

function DriverList({ areaId }: DriverListProps) {
    const [searchBar, setSearchBar] = useState("");
    const [alsoDisabled, setAlsoDisabled] = useState(false);
    const [onlyWithReportToDo, setOnlyWithReportToDo] = useState(false);

    const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);

    let whereConditions: WhereOptions[] = [];
    if (areaId !== undefined)
        whereConditions.push(['areaId', '==', areaId]);
    if (areaId === undefined && !admin.isMainAdmin)
        whereConditions.push(["areaId", "in", admin.areaIds])
    useFirestoreConnect([
        {
            collection: 'drivers',
            where: whereConditions.length === 0 ? undefined : whereConditions,
            orderBy: ['email', 'asc'],
        },
    ]);


    let drivers = useSelector((state: RootState) => state.firestore.ordered.drivers as Driver[]);

    if (!isLoaded(drivers)) {
        return <SplashScreen />
    }

    drivers = drivers.filter((d) => (d.email.toLowerCase().includes(searchBar.toLowerCase().replace(/\s+/g, '')))
        || (d.uid.toLowerCase().includes(searchBar.toLowerCase().replace(/\s+/g, '')))
        || (d.nominative && d.nominative.toLowerCase().includes(searchBar.toLowerCase().replace(/\s+/g, ''))));

    drivers = drivers.filter((d) => {
        const { totalAmount, cashDiffAmount } = getDriverCompleteAmounts(d.amounts);
        return (alsoDisabled || d.disabled === false) &&
            (!onlyWithReportToDo || totalAmount > 0 || cashDiffAmount > 0);
    });

    if (onlyWithReportToDo)
        drivers.sort((d1, d2) => {
            if (!d1.lastReportDate)
                return -1;
            if (!d2.lastReportDate)
                return 1;
            return moment(d1.lastReportDate.toMillis()).isSameOrBefore(moment(d2.lastReportDate.toMillis())) ? -1 : 1;
        });

    let driverComponents
    if (isEmpty(drivers))
        driverComponents = (
            <div>
                Nessun fattorino in base ai criteri di ricerca.
            </div>
        );
    else
        driverComponents = drivers.map((driver) => {
            let { totalAmount, cashDiffAmount } = getDriverCompleteAmounts(driver.amounts);
            return (
                <div className="card my-2" key={driver.uid}>
                    <Link to={"/drivers/" + driver.uid} className="card-link">
                        <div className="card-body d-flex justify-content-between" style={{ fontSize: 14 }}>
                            <div className="mr-2">
                                <div><b>ID:</b> {driver.uid}</div>
                                <div>
                                    <b>Email:</b> {driver.email}
                                </div>
                                <div><b>Nominativo:</b> {driver.nominative ?? ""}</div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <div><b>Da pagare:</b> {intToCurrency(totalAmount)}</div>
                                <div><b>Soldi alla mano: </b> {intToCurrency(cashDiffAmount)}</div>
                                <div><b>Ultimo report:</b> {driver.lastReportDate ? getDateString(driver.lastReportDate) : "-"}</div>
                            </div>

                        </div>
                    </Link>
                </div>
            )
        });

    return (
        <div className="driverList pb-3">
            <div className="d-flex justify-content-between flex-wrap my-3">
                <div className="form-inline">
                    <div className="form-group">
                        <label htmlFor="searchBar">Cerca per nome, per email o per id</label>
                        <input type="text" id="searchBar" className="form-control mx-sm-3" value={searchBar} onChange={(e) => setSearchBar(e.target.value)} />
                    </div>
                </div>
                <div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="onlyWithReportToDo" checked={onlyWithReportToDo} onChange={(e) => setOnlyWithReportToDo(e.target.checked)} />
                        <label className="custom-control-label" htmlFor="onlyWithReportToDo">Mostra solo fattorini con nuovi movimenti</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="alsoDisabled" checked={alsoDisabled} onChange={(e) => setAlsoDisabled(e.target.checked)} />
                        <label className="custom-control-label" htmlFor="alsoDisabled">Mostra anche i fattorini disabilitati</label>
                    </div>
                </div>
            </div>
            {driverComponents}
        </div>
    )
}

export default DriverList;