import moment from "moment";
import React, { FormEvent, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { toastr } from "react-redux-toastr";
import Movement, { MovementType } from "../../../models/movement";
import { createMovement, editMovement } from "../../../services/firestore_service";

type EditMovementProps = {
  entityType: "driver" | "supplier",
  entityId: string,
  movement?: Movement;
  initialSupplierPercentage?: number;
  show: boolean;
  onClose: () => void;
}

function EditMovementModal({ entityId, entityType, movement, show, initialSupplierPercentage, onClose }: EditMovementProps) {
  const [type, setType] = useState<MovementType>(entityType === "driver" ? "DRIVER_ORDER" : "SUPPLIER_ORDER");
  const [orderId, setOrderId] = useState("");
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [supplierPercentage, setSupplierPercentage] = useState<number | undefined>(undefined);
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [date, setDate] = useState(moment(moment().format("YYYY-MM-DD")));
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (show === true) {
      setType(movement?.type ?? (entityType === "driver" ? "DRIVER_ORDER" : "SUPPLIER_ORDER"));
      setOrderId(movement?.orderId ?? "");
      setAmount(movement !== undefined ? Math.abs(movement.amount / 100.0) : 0);
      setSupplierPercentage(movement !== undefined ? movement.supplierPercentage : initialSupplierPercentage)
      setIsAdd(movement !== undefined ? movement.amount > 0 : true);
      setDate(movement !== undefined ? moment(movement!.date.toMillis()) : moment(moment().format("YYYY-MM-DD")));
    }
  }, [show, movement]);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setConfirmLoading(true);
    try {
      if (movement === undefined) {
        await createMovement(entityId, entityType, type, orderId, isAdd ? amount! : -amount!, date, supplierPercentage);
        toastr.success("", "Movimento creato con successo!");
      }
      else {
        await editMovement(entityId, entityType, movement.id, type, orderId, isAdd ? amount! : -amount!, date, supplierPercentage);
        toastr.success("", "Movimento modificato con successo!");
      }
    }
    catch (err) {
      console.log(err)
      toastr.error("", "Errore inaspettato. Riprovare più avanti!");
    }
    finally {
      setConfirmLoading(false);
      onClose();
    }
  }

  return (
    <Modal show={show} onHide={confirmLoading ? () => { } : onClose}>
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton={!confirmLoading}>
          <Modal.Title>{movement === undefined ? "Crea movimento" : "Modifica movimento"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-inline">
            <label className="required">Tipologia movimento</label>
            <select className="form-control ml-3" value={type} required onChange={(e) => setType(e.target.value as MovementType)}
              disabled={confirmLoading}>
              <option value={entityType === "supplier" ? "SUPPLIER_ORDER" : "ORDER"}>Ordine</option>
              {
                entityType === "driver" && <option value="BONUS">Bonus</option>
              }
              <option value="CASH">Soldi alla mano</option>
              <option value="CASH_RECEIVED">Soldi alla mano ricevuti</option>
              <option value="OTHER">Altro</option>
            </select>
          </div>
          {
            (type === "SUPPLIER_ORDER" || type === "DRIVER_ORDER" || type === "CASH") &&
            <div className="form-inline mt-3">
              <label>ID Ordine</label>
              <input type="text" className="form-control ml-3" value={orderId} disabled={confirmLoading} onChange={(e) => setOrderId(e.target.value)} />
            </div>
          }
          <div className="custom-control custom-radio mt-3" onClick={() => setIsAdd(true)}>
            <input type="radio" id="is_add" className="custom-control-input" checked={isAdd} />
            <label className="custom-control-label" htmlFor="is_add">Aggiungi ammontare</label>
          </div>
          <div className="custom-control custom-radio mt-3" onClick={() => setIsAdd(false)}>
            <input type="radio" id="is_remove" className="custom-control-input" checked={!isAdd} />
            <label className="custom-control-label" htmlFor="is_remove">Rimuovi ammontare</label>
          </div>
          <div className="form-inline mt-3">
            <label>{type === "SUPPLIER_ORDER" ? "Ammontare totale dell'ordine" : "Ammontare"}</label>
            <NumberFormat
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              prefix="€"
              fixedDecimalScale={true}
              className="form-control ml-3"
              value={amount}
              disabled={confirmLoading}
              onValueChange={({ floatValue }) => {
                if (floatValue !== amount)
                  setAmount(floatValue);
              }} required />
          </div>
          {
            type === "SUPPLIER_ORDER" &&
            <div className="form-inline mt-3">
              <label>Percentuale ordine del fornitore</label>
              <NumberFormat
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={false}
                decimalSeparator=","
                prefix="%"
                fixedDecimalScale={true}
                id="supplierPercentage"
                className="form-control ml-3"
                value={supplierPercentage ? supplierPercentage * 100.0 : undefined}
                onValueChange={({ floatValue }) => {
                  if (floatValue !== supplierPercentage)
                    setSupplierPercentage(floatValue ? floatValue / 100.0 : undefined);
                }} required />
            </div>
          }

          <div className="form-inline mt-3">
            <label>Data</label>
            <input type="date" className="form-control ml-3" value={date.format("YYYY-MM-DD")} onChange={(e) => {
              if (e.target.value === "")
                return;
              setDate(moment(e.target.value));
            }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={onClose} disabled={confirmLoading}>
            Annulla
          </Button>
          <Button variant="success" type="submit" disabled={confirmLoading || amount === undefined || (type === "SUPPLIER_ORDER" && supplierPercentage === undefined)}>
            Conferma
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default EditMovementModal;