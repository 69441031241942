import React from "react";
import { useSelector } from "react-redux";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { RouteComponentProps, withRouter } from "react-router-dom"
import Admin from "../../models/admin";
import Area from "../../models/area";
import { RootState } from "../../store/reducers/rootReducer";
import DriverList from "./components/DriverList";

type DriverAreaScreenParams = {
    areaId: string
}

function DriverAreaScreen(props: RouteComponentProps<DriverAreaScreenParams, any, any>) {
    useFirestoreConnect({
        collection: 'areas',
        doc: props.match.params.areaId,
        storeAs: 'area',
    });

    const area = useSelector((state: RootState) => state.firestore.data.area as Area);
    const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);

    if (!isLoaded(area))
        return <div />;

    if (!admin.isMainAdmin && !admin.areaIds!.includes(area.id))
        return <div className="container my-4">Non hai accesso alla visualizzazione di questa pagina.</div>;

    return (
        <div className="my-4 container">
            <h4 className="my-auto">Fattorini</h4>
            <h6 className="mt-0" style={{ color: "#777777" }}>{area.name}</h6>
            <DriverList areaId={props.match.params.areaId} />
        </div>
    );
}

export default withRouter(DriverAreaScreen);