import React from 'react'
import { Modal, Button } from 'react-bootstrap';

type ConfirmationDialogProps = {
  show: boolean,
  modalTitle: string,
  modalMessage: string,
  onCancel: () => void;
  onConfirm: () => void;
}

function ConfirmationDialog({ modalMessage, modalTitle, onCancel, onConfirm, show }: ConfirmationDialogProps) {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle ?? "Confermi?"}</Modal.Title>
      </Modal.Header>
      {
        modalMessage &&
        <Modal.Body>
          {modalMessage}
        </Modal.Body>
      }
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={onCancel} >
          Annulla
        </Button>
        <Button variant="primary" type="button" onClick={onConfirm}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationDialog