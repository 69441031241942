import React, { useState } from 'react'
import moment from 'moment';
import { Multiselect } from 'react-widgets'
import OrderStates from '../../models/order_states';
import Area from '../../models/area';
import ReactExport from "react-export-excel";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import Order, { getSupplierAmountForOrder } from '../../models/order';
import Admin from '../../models/admin';
import { isEmpty, isLoaded, useFirestoreConnect, WhereOptions } from 'react-redux-firebase';
import { doubleToCurrency, doubleToPercentage } from '../../helpers/currencyHelper';
import { getDateString, getTimeString } from '../../helpers/dateHelper';
import { Link } from 'react-router-dom';
import OrderStatus from './OrderStatus';
import firebase from 'firebase/app';
import SplashScreen from '../SplashScreen';

type OrderHistoryProps = {
  area?: Area;
  driverId?: string;
  supplierId?: string;
  customerId?: string;
  adminId?: string;
  viewMode?: 'driver' | 'supplier' | 'admin'
}

function OrderHistory({ area, customerId, driverId, supplierId, adminId, viewMode }: OrderHistoryProps) {
  let today = moment();
  const [fromDate, setFromDate] = useState(today.format("YYYY-MM-DD"))
  const [toDate, setToDate] = useState(today.format("YYYY-MM-DD"))
  const [searchBar, setSearchBar] = useState("");
  const [orderStates, setOrderStates] = useState<OrderStates[]>([]);
  const [onlyNotDriverSeen, setOnlyNotDriverSeen] = useState(false);

  return (
    <div className="orderHistory">
      <h4 className="my-auto">Storico ordini</h4>
      {area !== undefined &&
        <h6 className="mt-0" style={{ color: "#777777" }}>{area.name}</h6>
      }
      <div className="d-flex flex-wrap">
        <div className="form-inline mr-auto my-3">
          <div className="form-group">
            <label htmlFor="searchBar">Cerca per numero ordine</label>
            <input type="text" id="searchBar" className="form-control mx-sm-3" value={searchBar} onChange={(e) => setSearchBar(e.target.value)} />
          </div>
        </div>
        <div className="d-flex flex-end flex-wrap">
          <div className="d-flex form-inline">
            <div className="form-group">
              <label htmlFor="fromDate">Da</label>
              <input type="date" id="fromDate" className="form-control mx-sm-3" value={fromDate} onChange={(e) => {
                if (e.target.value === "")
                  return;
                setFromDate(e.target.value);
              }} />
            </div>
          </div>
          <div className="d-flex form-inline my-3">
            <div className="form-group">
              <label htmlFor="toDate">a</label>
              <input type="date" id="toDate" className="form-control mx-sm-3" value={toDate} onChange={(e) => {
                if (e.target.value === "")
                  return;
                setToDate(e.target.value);
              }} />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center">
          <label className="required mr-3 my-0">Stato</label>
          <Multiselect
            valueField='id'
            textField='name'
            data={[
              { id: "NEW", name: "Nuovo" },
              { id: "ACCEPTED", name: "In preparazione" },
              { id: "READY", name: "Pronto" },
              { id: "PICKED_UP", name: "In consegna" },
              { id: "DELIVERED", name: "Consegnato al cliente" },
              { id: "REFUSED", name: "Rifiutato" },
              { id: "CANCELLED", name: "Annullato" },
            ]}
            value={orderStates.map((s) => ({ id: s }))}
            onChange={(states) => {
              const orderStates = states.map((s) => s.id) as OrderStates[];
              if (orderStates.length > 0 && orderStates.find((s) => ["ACCEPTED", "READY"].includes(s)) === undefined) {
                setOrderStates(orderStates);
                setOnlyNotDriverSeen(false);
              }
              else
                setOrderStates(orderStates);
            }}
            messages={{
              emptyList: 'Non ci sono altri stati disponibili',
              noneSelected: "Tutti gli ordini"
            }}
          />
        </div>
        {(orderStates.length === 0 || orderStates.find((s) => ["ACCEPTED", "READY"].includes(s)) !== undefined) &&
          <div className="custom-control custom-checkbox my-2">
            <input type="checkbox" className="custom-control-input" id="onlyNotDriverSeen" checked={onlyNotDriverSeen} onChange={(e) => setOnlyNotDriverSeen(e.target.checked)} />
            <label className="custom-control-label" htmlFor="onlyNotDriverSeen">Mostra solo gli ordini non visualizzati dal fattorino</label>
          </div>
        }
      </div>
      <OrderList
        area={area}
        toDate={toDate}
        fromDate={fromDate}
        orderStates={orderStates}
        searchBar={searchBar}
        viewMode={viewMode}
        onlyNotDriverSeen={onlyNotDriverSeen}
        customerId={customerId}
        driverId={driverId}
        supplierId={supplierId}
        adminId={adminId} />
    </div>
  )
}

export default OrderHistory;


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type OrderListProps = {
  orderStates: OrderStates[],
  fromDate: string;
  toDate: string;
  searchBar: string;
  onlyNotDriverSeen: boolean;
  area: Area | undefined;
  driverId: string | undefined;
  supplierId: string | undefined;
  customerId: string | undefined;
  adminId: string | undefined
  viewMode: 'driver' | 'supplier' | 'admin' | undefined
}

function OrderList({ fromDate, viewMode, onlyNotDriverSeen, supplierId, driverId, customerId, adminId, area, orderStates, searchBar, toDate }: OrderListProps) {
  let { orders, areas, admin } = useSelector((state: RootState) => ({
    orders: state.firestore.ordered['orders'] as Order[],
    areas: state.firestore.ordered['areas'] as Area[],
    admin: state.firestore.data.currentAdmin as Admin
  }));

  useFirestoreConnect(() => {
    let lowDate = moment(fromDate, "YYYY-MM-DD");
    let highDate = moment(toDate, "YYYY-MM-DD").add(1, 'days');

    let lowTimestamp = firebase.firestore.Timestamp.fromDate(lowDate.toDate());
    let highTimestamp = firebase.firestore.Timestamp.fromDate(highDate.toDate());

    let whereConditions: WhereOptions[] = [];

    if (area) {
      whereConditions.push(["areaId", "==", area.id]);
    }
    else if (!admin.isMainAdmin) {
      whereConditions.push(["areaId", "in", admin.areaIds]);
    }

    if (driverId)
      whereConditions.push(["driverId", "==", driverId]);
    if (supplierId)
      whereConditions.push(["supplierId", "==", supplierId]);
    if (customerId)
      whereConditions.push(["customerId", "==", customerId]);
    if (adminId)
      whereConditions.push(["adminId", "==", adminId]);

    return [
      {
        collection: 'orders',
        orderBy: [['preferredDeliveryTimestamp', 'desc']],
        where: [
          ...whereConditions,
          ['preferredDeliveryTimestamp', '>=', lowTimestamp],
          ['preferredDeliveryTimestamp', '<=', highTimestamp]
        ],
      },
      {
        collection: 'areas',
        ...!admin.isMainAdmin ? {
          where: ["id", "in", admin.areaIds]
        } : {}
      }
    ];
  })

  if (!isLoaded(orders, areas)) {
    return <SplashScreen />
  }

  let deliveredOrders = orders.filter((o) => o.state === "DELIVERED");

  if (orderStates.length > 0)
    orders = orders.filter((o) => orderStates.includes(o.state));

  if (onlyNotDriverSeen)
    orders = orders.filter((o) => o.driverSeen !== true && o.useOwnDrivers !== true);

  orders = orders.filter((o) => o.reference.id.toLowerCase().includes(searchBar.toLowerCase().replace(/\s+/g, '')));

  if (isEmpty(orders))
    return (
      <div className="orderList my-4">
        Nessun ordine in base ai criteri di ricerca.
      </div>
    );

  let orderComponents = orders.map((order) => {
    return (
      <div className="card my-2" key={order.reference.id}>
        <div className="card-body">
          <div className="row justify-content-between">
            <div className="col-md-4 col-sm-12">
              <Link to={"/orders/" + order.reference.id}>
                Ordine <b>#{order.reference.id.toUpperCase()}</b>
              </Link>
              <div>Ritiro il giorno {getDateString(order.shiftStartTime)} alle {getTimeString(order.shiftStartTime)}</div>
              <OrderStatus order={order} />
              {
                (order.state === "ACCEPTED" || order.state === "READY" || order.state === "PICKED_UP") && order.useOwnDrivers !== true
                && (
                  order.driverSeen === true ?
                    <div><i className="fa fa-user"></i> {order.driverName} <span className="fas fa-check-double" style={{ color: "green" }} /></div> :
                    <div><i className="fa fa-user"></i> {order.driverName} <span className="fas fa-check" style={{ color: "darkgrey" }} /> </div>
                )
              }
              {
                order.useOwnDrivers === true && <div><i className="fas fa-truck mr-2" />Consegna gestita dal fornitore</div>
              }
            </div>
            <div className="col-md-4 col-sm-12 my-auto">
              <div className="mt-1"><i className="fas fa-utensils" style={{ width: "24px" }}></i> {order.supplierName}</div>
              {order.useCashPayment === true ?
                <div><i className="fas fa-money-bill-wave px-auto" style={{ width: "24px" }}></i> Pagamento in contanti</div> :
                <div><i className="far fa-credit-card"></i> Pagamento con carta </div>
              }
              <div><i className="fas fa-euro-sign" style={{ width: "24px" }}></i> {doubleToCurrency(order.cartAmount)}</div>
              <div><i className="fas fa-truck" style={{ width: "24px" }}></i> {order.useOwnDrivers === true ? ("Fornitore (" + doubleToCurrency(order.deliveryAmount) + ")") : doubleToCurrency(order.deliveryAmount)}</div>
              {
                viewMode === 'supplier' && order.state === "DELIVERED" &&
                <b>Ammontare fornitore: {doubleToCurrency(getSupplierAmountForOrder(order).amountWithoutIVA)} ({doubleToPercentage(order.supplierPercentage)})</b>
              }
              {
                viewMode === 'driver' && order.state === "DELIVERED" && !order.useOwnDrivers &&
                <b>Compenso fattorino: {doubleToCurrency(order.driverAmount)}</b>
              }
              {
                viewMode === 'admin' && order.state === "DELIVERED" &&
                <b>Ammontare amministratore: {doubleToCurrency((order.cartAmount - (order.discountAmount ?? 0)) * order.adminPercentage)} ({doubleToPercentage(order.adminPercentage)})</b>
              }
            </div>
          </div>
        </div>
      </div>
    )
  });

  let exportToExcelComponent = <div />;

  if (deliveredOrders.length > 0) {
    exportToExcelComponent = <ExcelFile element={<button className="btn btn-outline-success">Esporta ordini</button>}
      filename={"Ordini_" + fromDate + "_" + toDate}>
      <ExcelSheet data={deliveredOrders.map((o) => ({
        id: o.reference.id,
        supplierName: o.supplierName,
        areaName: areas.find((a) => a.id === o.areaId)?.name ?? "",
        cartAmount: doubleToCurrency(o.cartAmount),
        deliveryAmount: doubleToCurrency(o.deliveryAmount),
        date: getDateString(o.shiftStartTime),
      }))} name="Ordini">
        <ExcelColumn label="CODICE ORDINE" value="id" />
        <ExcelColumn label="PARTNER" value="supplierName" />
        <ExcelColumn label="CITTA'" value="areaName" />
        <ExcelColumn label="TOTALE ORDINE" value="cartAmount" />
        <ExcelColumn label="SPESE DI SPEDIZIONE'" value="deliveryAmount" />
        <ExcelColumn label="DATA DELL'ORDINE" value="date" />
      </ExcelSheet>
    </ExcelFile>
  }

  let totalAmountComponents = <div />;

  let deliveryAmount = deliveredOrders.reduce((prev, curr) => prev + (curr.useOwnDrivers === true ? 0 : curr.deliveryAmount), 0.0);
  if (viewMode === 'supplier') {
    let amount = deliveredOrders.reduce((prev, curr) => prev + getSupplierAmountForOrder(curr).amountWithoutIVA, 0.0);
    let platformAmount = deliveredOrders.reduce((prev, curr) => prev + curr.cartAmount * (1 - curr.supplierPercentage), 0.0);
    totalAmountComponents = <div className="my-2">
      <div>Ordini arrivati a destinazione*: <b>{deliveredOrders.length}</b></div>
      <div>Ammontare incassato*: <b>{doubleToCurrency(platformAmount)}</b></div>
      <div>Ammontare dovuto al fornitore (IVA esclusa)*: <b>{doubleToCurrency(amount)}</b></div>
      <div>Ammontare dovuto al fornitore (IVA inclusa)*: <b>{doubleToCurrency(amount - platformAmount * 0.22)}</b></div>
      <div>Totale costo spedizione*: <b>{doubleToCurrency(deliveryAmount)}</b></div>
      <div className="mt-2 small">* I dati sono riferiti al periodo selezionato</div>
    </div>
  }
  else if (viewMode === 'driver') {
    let amount = deliveredOrders.reduce((prev, curr) => prev + curr.driverAmount, 0.0);
    let cashAmount = deliveredOrders.reduce((prev, curr) => prev + (curr.useCashPayment === true ? (curr.cartAmount + curr.deliveryAmount - (curr.discountAmount ?? 0)) : 0), 0.0);
    totalAmountComponents = <div className="my-2">
      <div>Ordini arrivati a destinazione*: <b>{deliveredOrders.length}</b></div>
      <div>Ammontare dovuto al fattorino*: <b>{doubleToCurrency(amount)}</b></div>
      <div>Totale costo spedizione*: <b>{doubleToCurrency(deliveryAmount)}</b></div>
      <div>Soldi alla mano*: <b>{doubleToCurrency(cashAmount)}</b></div>
      <div className="mt-2 small">* I dati sono riferiti al periodo selezionato</div>
    </div>
  }
  else if (viewMode === 'admin') {
    let amount = deliveredOrders.reduce((prev, curr) => prev + (curr.cartAmount - (curr.discountAmount ?? 0)) * curr.adminPercentage, 0.0);
    totalAmountComponents = <div className="my-2">
      <div>Ordini arrivati a destinazione*: <b>{deliveredOrders.length}</b></div>
      <div>Ammontare dovuto all'amministratore*: <b>{doubleToCurrency(amount)}</b></div>
      <div>Totale costo spedizione*: <b>{doubleToCurrency(deliveryAmount)}</b></div>
      <div className="mt-2 small">* I dati sono riferiti al periodo selezionato</div>
    </div>
  }
  else {
    let amount = deliveredOrders.reduce((prev, curr) => prev + curr.cartAmount, 0.0);
    totalAmountComponents = <div className="my-2">
      <div>Ordini arrivati a destinazione*: <b>{deliveredOrders.length}</b></div>
      <div>Totale ordini (solo prodotti)*: <b>{doubleToCurrency(amount)}</b></div>
      <div>Totale costo spedizione*: <b>{doubleToCurrency(deliveryAmount)}</b></div>
      <div className="mt-2 small">* I dati sono riferiti al periodo selezionato</div>
    </div>
  }

  return (
    <div className="orderList my-4">
      <div className="d-flex justify-content-between align-items-center">
        {totalAmountComponents}
        {exportToExcelComponent}
      </div>
      {orderComponents}
    </div>
  )
}