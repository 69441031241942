import React from 'react'
import { connect, useSelector } from 'react-redux'
import { NavLink, Link } from "react-router-dom";
import { Nav, Navbar, Button } from "react-bootstrap";
import { signOut } from '../store/actions/authActions';

const MyNavbar = (props) => {
  const admin = useSelector((state) => state.firestore.data.currentAdmin);

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Navbar.Brand as={Link} to="/" >
        Taporty
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Nav.Link as={NavLink} exact to="/">Ordini</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/shifts">Turni</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/drivers">Fattorini</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/suppliers">Fornitori</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/customers">Clienti</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/discounts">Sconti</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/areas">Aree</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Link as={NavLink} to="/work_with_us">Lavora con noi</Nav.Link>
        </Nav>
        {
          admin?.isMainAdmin === true &&
          <Nav>
            <Nav.Link as={NavLink} to="/admin">Amministratori</Nav.Link>
          </Nav>
        }
        {
          admin?.isMainAdmin === true &&
          <Nav>
            <Nav.Link as={NavLink} to="/settings">Impostazioni</Nav.Link>
          </Nav>
        }
        <Nav className="ml-auto">
          <Button variant="outline-light" onClick={props.signOut}>Esci</Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(MyNavbar)
