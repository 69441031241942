import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

class ButtonWithConfirmation extends Component {
  state = {
    showModal: false,
  }

  handleClick = () => {
    this.setState({
      showModal: true,
    })
  }

  handleCancel = () => {
    this.setState({
      showModal: false,
    })
  }

  handleConfirm = (e) => {
    e.preventDefault();
    this.props.onConfirm();
    this.setState({
      showModal: false,
    })
  }

  render() {
    return (
      <div>
        <button className={this.props.className ?? "btn btn-primary"} style={this.props.style} onClick={this.handleClick} disabled={this.props.disabled ?? false}>{this.props.buttonText}</button>
        <Modal show={this.state.showModal} onHide={this.handleCancel}>
          <form onSubmit={this.handleConfirm}>
            <Modal.Header closeButton>
              <Modal.Title>{this.props.modalTitle ?? "Confermi?"}</Modal.Title>
            </Modal.Header>
            {
              this.props.modalMessage &&
              <Modal.Body>
                {this.props.modalMessage}
              </Modal.Body>
            }
            <Modal.Footer>
              <Button variant="secondary" type="button" onClick={this.handleCancel} >
                Annulla
            </Button>
              <Button variant="primary" type="submit">
                Conferma
            </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}
export default ButtonWithConfirmation