import React, { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import OrderStatus from './OrderStatus'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Cart from './Cart'
import { getDateString, getDateTimeString, getTimeString } from '../../helpers/dateHelper';
import { updateOrderState } from '../../store/actions/orderActions';
import { Link } from 'react-router-dom';
import ChangeStateButton from './ChangeStateButton';
import { doubleToCurrency, doubleToPercentage } from '../../helpers/currencyHelper';
import ChangeDriverModal from './ChangeDriverModal';
import Rating from 'react-rating'
import { useAppDispatch } from '../../store/store';
import { RootState } from '../../store/reducers/rootReducer';
import Order, { getSupplierAmountForOrder } from '../../models/order';
import OrderEvent from '../../models/order_event';
import Admin from '../../models/admin';
import Review from '../../models/review';
import UserPreview from '../../components/UserPreview';
import SplashScreen from '../SplashScreen';
import Supplier from '../../models/supplier';

function OrderDetailsScreen(props: RouteComponentProps<{ orderId: string }, any, any>) {
  const { order, events, review, admin, updateStateLoading, supplier } = useSelector((state: RootState) => ({
    order: state.firestore.data.order as Order,
    events: state.firestore.ordered['events'] as OrderEvent[],
    review: state.firestore.data.reviews?.[props.match.params.orderId] as Review,
    admin: state.firestore.data.currentAdmin as Admin,
    updateStateLoading: state.order.updateStateLoading,
    supplier: state.firestore.data.supplier as Supplier,
  }));

  useFirestoreConnect(() => [
    {
      collection: 'orders',
      doc: props.match.params.orderId,
      storeAs: 'order'
    },
    {
      collection: 'reviews',
      doc: props.match.params.orderId,
    },
    {
      collection: 'orders',
      doc: props.match.params.orderId,
      subcollections: [{ collection: 'events' }],
      orderBy: [['timestamp', 'desc']],
      storeAs: 'events'
    },
    ...(isLoaded(order) ? [
      {
        collection: 'suppliers',
        doc: order.supplierId,
        storeAs: "supplier",
      }
    ] : [])
  ]);

  const dispatch = useAppDispatch();


  const [showChangeDriverModal, setShowChangeDriverModal] = useState(false);

  if (!isLoaded(order, events, supplier))
    return <SplashScreen />

  if (isEmpty(order))
    return <div className="container my-4">Ordine inesistente.</div>;

  if (!admin.isMainAdmin && !admin.areaIds!.includes(order.areaId))
    return <div className="container my-4">Non hai accesso alla visualizzazione di questo ordine.</div>;

  let customerInfo = (
    <div>
      <div><i className="fas fa-map-marker-alt mr-2" />{order.customerAddress}</div>
      <div className="mt-1">
        Consegna per il giorno {getDateTimeString(order.preferredDeliveryTimestamp)} (da consegnare al fattorino alle {getTimeString(order.shiftStartTime)})
      </div>
      {order.useCashPayment === true ?
        <div className="mt-1">
          <i className="fas fa-money-bill-wave mr-2" />
          Pagamento in contanti {order.supplierResponsibleForCash === true && "(contanti gestiti dal fornitore)"}
        </div> :
        <div className="mt-1"><i className="far fa-credit-card mr-2"></i>Pagamento con carta </div>
      }
    </div>
  )

  let noteComponent: ReactNode = <></>;
  let moreProductsRequestComponent: ReactNode = <></>;

  if (order.notes !== "" && order.notes !== undefined && order.notes !== null)
    noteComponent = (<div>
      <h5 className="mt-3">Note aggiuntive per il fattorino</h5>
      <p>
        {order.notes}
      </p>
    </div>);


  let cartComponent: JSX.Element;

  if (order.createdBySupplier === true)
    cartComponent = <div className="card card-body mb-2">
      Questo ordine è stato creato da gestionale, l'elenco dei prodotti non è perciò disponibile.
    </div>;
  else
    cartComponent = <div className="card card-body mb-2">
      <Cart products={order.products} total={order.cartAmount} />
      {noteComponent}
    </div>;

  if (order.moreProductsRequested && (order.state === "ACCEPTED" || order.state === "READY"))
    moreProductsRequestComponent = <div className="card card-body mb-2">
      <Cart products={order.moreProductsRequest!.products} total={order.moreProductsRequest!.cartAmount} title="Aggiunte richieste" />
      {noteComponent}
    </div>;


  let userComponents = (
    <div>
      {
        review &&
        <div className="card card-body mb-2">
          <h5>Recensione cliente</h5>
          <b>Valutazione servizio</b>
          <div className="d-flex align-items-center">
            <Rating readonly={true} initialRating={review.serviceRating}
              fullSymbol={<i className="fas fa-star"></i>}
              emptySymbol={<i className="far fa-star"></i>}
            />
            <div className="ml-2">{review.serviceRating.toFixed(0)}/5</div>
          </div>
          <div className="mt-1" style={{ color: "grey", fontSize: "15px" }}>{review.serviceReview}</div>
          <b className="mt-2">Valutazione fornitore</b>
          <div className="d-flex align-items-center">
            <Rating readonly={true} initialRating={review.supplierRating}
              fullSymbol={<i className="fas fa-star"></i>}
              emptySymbol={<i className="far fa-star"></i>}
            />
            <div className="ml-2">{review.supplierRating.toFixed(0)}/5</div>
          </div>
          <div className="mt-1" style={{ color: "grey", fontSize: "15px" }}>{review.supplierReview}</div>
        </div>
      }
      <div className="card card-body mt-2">
        {
          order.createdBySupplier === true ?
            <h5>Cliente</h5> :
            <Link to={"/customers/" + order.customerId} className="h5">
              Cliente
            </Link>
        }
        <UserPreview name={order.customerName} imageUrl={order.customerImageUrl} phoneNumber={order.customerPhoneNumber} child={customerInfo} />
      </div>
      <div className="card card-body mt-2">
        <Link to={"/suppliers/" + order.supplierId} className="h5">
          Fornitore
        </Link>
        <UserPreview name={order.supplierName} imageUrl={order.supplierImageUrl} phoneNumber={order.supplierPhoneNumber} child={
          order.useOwnDrivers === true && <div><i className="fas fa-truck mr-2" />Consegna gestita dal fornitore</div>
        } />
      </div>
      {
        (order.state !== "REFUSED" && order.state !== "CANCELLED") && order.useOwnDrivers !== true &&
        <div className="card card-body mt-2">
          <Link to={"/drivers/" + order.driverId} className="h5">
            Fattorino
          </Link>
          <UserPreview name={order.driverName} imageUrl={order.driverImageUrl} phoneNumber={order.driverPhoneNumber} />
          {
            order.driverSeen === true || order.state === "DELIVERED" ?
              <div><span className="fas fa-check-double mr-2" style={{ color: "green" }} /> Il fattorino ha visualizzato l&apos;ordine.</div> :
              <div><span className="fas fa-check mr-2" style={{ color: "darkgrey" }} /> Il fattorino non ha ancora visualizzato l&apos;ordine.</div>
          }
        </div>
      }
    </div>
  );

  let eventComponent = <div className="mt-4"><OrderEventList events={events} /></div>;

  let buttonComponent = <div className="d-flex justify-content-end">
    {(order.state === "NEW" || order.state === "ACCEPTED" || order.state === "READY") &&
      <>
        <button className="btn btn-outline-success mr-2" onClick={() => setShowChangeDriverModal(true)}>Cambia fattorino</button >
        <ChangeDriverModal show={showChangeDriverModal} startTime={order.shiftStartTime}
          onClose={() => setShowChangeDriverModal(false)} orderId={order.reference.id} areaId={order.areaId}
          canUseOwnDrivers={supplier.canUseOwnDrivers === true} isUsingOwnDriver={order.useOwnDrivers === true} />
      </>
    }
    <ChangeStateButton disabled={updateStateLoading} initialState={order.state} onConfirm={(state) => dispatch(updateOrderState(props.match.params.orderId, state))} />
    <div className="mx-1"></div>
  </div>

  let amountsComponent = (
    <div className="card card-body mb-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex">Spesa per i prodotti: </div>
        <div>{doubleToCurrency(order.cartAmount)}</div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex">Spesa per la consegna: </div>
        <div>{order.deliveryAmount === 0 ? "Gratuita" : doubleToCurrency(order.deliveryAmount)}</div>
      </div>
      {
        order.discountCodeId &&

        <div className="d-flex justify-content-between">
          <div className="d-flex">Sconto (<Link to={"/discounts/" + order.discountCodeId}>{order.discountCode}</Link>): </div>
          <div>
            {
              order.discountPercentage ?
                <div>-{doubleToCurrency(order.discountAmount)} ({doubleToPercentage(order.discountPercentage)})</div> :
                <div>-{doubleToCurrency(order.discountAmount)}</div>
            }
          </div>
        </div>
      }
      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex"><b>Totale: </b></div>
        <div><b>{doubleToCurrency(order.cartAmount + order.deliveryAmount - (order.discountAmount ?? 0))}</b></div>
      </div>
      {
        order.state === "DELIVERED" &&
        <div>
          <hr />
          {order.useOwnDrivers !== true &&
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex"><b>Ammontare fattorino: </b></div>
              <div><b>{doubleToCurrency(order.driverAmount)}</b></div>
            </div>
          }
          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex"><b>Ammontare fornitore: </b></div>
            <div><b>{doubleToCurrency(getSupplierAmountForOrder(order).amountWithoutIVA)}</b></div>
          </div>
        </div>
      }
    </div>
  );

  return (
    <div className="orderDetails my-3 mx-5">
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <h4>Ordine #{order.reference.id.toUpperCase()}</h4>
              <h5><OrderStatus order={order} /></h5>
              {order.state === "REFUSED" && <div>Motivo del rifiuto: {order.refusalReason ?? "Non specificato"}</div>}
              <div></div>
            </div>
            <div className="col-md-4 col-sm-12 my-auto">
              {buttonComponent}
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-md-block">
        <div className="row">
          <div className="col-md-8">
            {moreProductsRequestComponent}
            {cartComponent}
            {eventComponent}
          </div>
          <div className="col-md-4">
            {amountsComponent}
            {userComponents}
          </div>
        </div>
      </div>
      <div className="d-md-none">
        {amountsComponent}
        {moreProductsRequestComponent}
        {cartComponent}
        {userComponents}
        {eventComponent}
      </div>
    </div>
  )
}


export default withRouter(OrderDetailsScreen);

function OrderEventList({ events }: { events: OrderEvent[] }) {
  if (events.length === 0)
    return <div />

  return (
    <div className="my-2">
      <h5>Storico eventi</h5>
      <table className="table table-bordered">
        <thead className="table-info">
          <tr>
            <th>Data</th>
            <th>Ora</th>
            <th>Descrizione</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) =>
            <tr key={event.timestamp.toMillis()}>
              <td style={{ whiteSpace: "nowrap" }}>{getDateString(event.timestamp)}</td>
              <td style={{ whiteSpace: "nowrap" }}>{getTimeString(event.timestamp)}</td>
              <td>
                {event.description}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}