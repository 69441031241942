
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Admin from "../../models/admin";
import Driver from "../../models/driver";
import Supplier from "../../models/supplier";
import { RootState } from "../../store/reducers/rootReducer";
import { toastr } from "react-redux-toastr";
import { createDriverReport, createSupplierReport } from "../../services/firestore_service";
import { DriverAmounts } from "../../models/driver_report";
import { intToCurrency } from "../../helpers/currencyHelper";
import ReportCard from "./components/ReportCard";
import ButtonWithConfirmation from "../../components/ButtonWithConfirmation";
import Checkbox from "../../components/Checkbox";
import { CurrencyInputWithTitle } from "../../components/CurrencyInput";
import SplashScreen from "../SplashScreen";
import { SupplierAmounts } from "../../models/supplier_report";

type CreateReportScreenProps = RouteComponentProps<{ id: string }, any, any> & {
  type: "driver" | "supplier"
}

function CreateReportScreen(props: CreateReportScreenProps) {
  const entity = useSelector((state: RootState) => state.firestore.data[props.type] as Supplier | Driver);
  const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);
  const [totalOrderAmount, setTotalOrderAmount] = useState<number | null>(null);
  const [orderAmountNoIva, setOrderAmountNoIva] = useState<number | null>(null);
  const [orderAmount, setOrderAmount] = useState<number | null>(null);
  const [bonusAmount, setBonusAmount] = useState<number | null>(null);
  const [otherAmount, setOtherAmount] = useState<number | null>(null);
  const [cashAmount, setCashAmount] = useState<number | null>(null);
  const [cashReceivedAmount, setCashReceivedAmount] = useState<number | null>(null);
  const [useCashToPay, setUseCashToPay] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const driver = entity as Driver;
  const supplier = entity as Supplier;

  useEffect(() => {
    if (entity) {
      if (props.type === "supplier") {
        if (totalOrderAmount === null)
          setTotalOrderAmount(supplier.amounts.totalOrderAmount);
        if (orderAmountNoIva === null)
          setOrderAmountNoIva(supplier.amounts.orderAmountNoIva);
      }
      else {
        if (bonusAmount === null)
          setBonusAmount(driver.amounts.bonusAmount);
      }
      if (orderAmount === null)
        setOrderAmount(entity.amounts.orderAmount);
      if (otherAmount === null)
        setOtherAmount(entity.amounts.otherAmount);
      if (cashAmount === null)
        setCashAmount(entity.amounts.cashAmount);
      if (cashReceivedAmount === null)
        setCashReceivedAmount(entity.amounts.cashReceivedAmount);
    }
  }, [entity]);

  useFirestoreConnect([
    {
      collection: props.type === "driver" ? "drivers" : "suppliers",
      doc: props.match.params.id,
      storeAs: props.type,
    },
  ]);

  if (!isLoaded(entity, admin))
    return <SplashScreen />;

  if (isEmpty(entity))
    return <div className="container my-4">{props.type === "driver" ? "Fattorino" : "Fornitore"} inesistente.</div>;

  if (!admin.isMainAdmin && !admin.areaIds!.includes(entity.areaId))
    return <div className="container my-4">Non hai accesso alla visualizzazione di questo {props.type === "driver" ? "fattorino" : "fornitore"}.</div>;

  const basePath = "/" + props.type + "s/" + props.match.params.id + "/";
  const showCash = props.type === "driver" || supplier.canUseOwnDrivers === true || supplier.responsibleForCash === true;

  let driverAmounts: DriverAmounts | null = null;
  let supplierAmounts: SupplierAmounts | null = null;
  let canConfirm = false;

  if (props.type === "driver" && orderAmount !== null && otherAmount !== null &&
    cashAmount !== null && cashReceivedAmount !== null && bonusAmount !== null) {
    driverAmounts = {
      orderAmount,
      bonusAmount,
      otherAmount,
      cashAmount,
      cashReceivedAmount,
    };
    canConfirm = true;
  }

  if (props.type === "supplier" && orderAmount !== null && otherAmount !== null &&
    cashAmount !== null && cashReceivedAmount !== null && orderAmountNoIva !== null && totalOrderAmount !== null) {
    supplierAmounts = {
      orderAmount,
      otherAmount,
      cashAmount,
      cashReceivedAmount,
      orderAmountNoIva,
      totalOrderAmount,
    };
    canConfirm = true;
  }

  async function handleSubmit() {
    setConfirmLoading(true);
    try {
      let reportId: string;

      if (props.type === "driver")
        reportId = await createDriverReport(
          props.match.params.id,
          driver.nominative,
          driverAmounts!,
          cashAmount! - cashReceivedAmount! > 0 && showCash && useCashToPay,
        );
      else
        reportId = await createSupplierReport(
          props.match.params.id,
          supplier.name,
          supplierAmounts!,
          cashAmount! - cashReceivedAmount! > 0 && showCash && useCashToPay,
        );

      await props.history.push(basePath + "reports/" + reportId);
    }
    catch (err) {
      console.log(err)
      toastr.error("", "Errore inaspettato durante la creazione del resoconto.");
      setConfirmLoading(false);
    }
  }

  return <div className="container my-4">
    <div className="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h2>Genera resoconto</h2>
        <h4>{props.type === "driver" ? ("Fattorino " + driver.nominative) : ("Fornitore " + supplier.name)}</h4>
      </div>
      <ButtonWithConfirmation
        className="btn btn-success"
        modalTitle="Genera resoconto"
        modalMessage="Confermare la creazione del resoconto?"
        buttonText="Genera"
        onConfirm={handleSubmit}
        disabled={confirmLoading || !canConfirm} />
    </div>
    <div className="card card-body">
      <div className="d-flex justify-content-start flex-wrap">
        {props.type === "supplier" &&
          <>
            <div className="mr-4 mb-2">
              <CurrencyInputWithTitle
                title="Totale ordini"
                amount={totalOrderAmount}
                setAmount={setTotalOrderAmount}
                isInteger={true}
                required={true}
                allowNegative={true}
                hint={"Attuali: " + intToCurrency((entity.amounts as SupplierAmounts).totalOrderAmount)} />
            </div>
            <div className="mr-4 mb-2">
              <CurrencyInputWithTitle
                title="Guadagno personale (IVA esclusa)"
                amount={orderAmountNoIva}
                setAmount={setOrderAmountNoIva}
                isInteger={true}
                required={true}
                allowNegative={true}
                hint={"Attuali: " + intToCurrency((entity.amounts as SupplierAmounts).orderAmountNoIva)} />
            </div>
          </>}
        <div className="mr-4 mb-2">
          <CurrencyInputWithTitle
            title={props.type === "driver" ? "Entrate ordini" : "Guadagno personale (IVA inclusa)"}
            amount={orderAmount}
            setAmount={setOrderAmount}
            isInteger={true}
            required={true}
            allowNegative={true}
            hint={"Attuali: " + intToCurrency(entity.amounts.orderAmount)} />
        </div>
        {
          props.type === "driver" &&
          <div className="mr-4 mb-2">
            <CurrencyInputWithTitle
              title="Entrate bonus"
              amount={bonusAmount}
              setAmount={setBonusAmount}
              isInteger={true}
              required={true}
              allowNegative={true}
              hint={"Attuali: " + intToCurrency(driver.amounts.bonusAmount)} />
          </div>
        }
        <div className="mr-4 mb-2">
          <CurrencyInputWithTitle
            title="Entrate non categorizzate"
            amount={otherAmount}
            setAmount={setOtherAmount}
            isInteger={true}
            required={true}
            allowNegative={true}
            hint={"Attuali: " + intToCurrency(entity.amounts.otherAmount)} />
        </div>
      </div>
      {showCash && <div className="d-flex justify-content-start flex-wrap">
        <div className="mr-4 mb-2">
          <CurrencyInputWithTitle
            title="Soldi alla mano accumulati"
            amount={cashAmount}
            setAmount={setCashAmount}
            isInteger={true}
            required={true}
            allowNegative={true}
            hint={"Attuali: " + intToCurrency(entity.amounts.cashAmount)} />
        </div>
        <div className="mr-4 mb-2">
          <CurrencyInputWithTitle
            title="Soldi alla mano ricevuti"
            amount={cashReceivedAmount}
            setAmount={setCashReceivedAmount}
            isInteger={true}
            required={true}
            allowNegative={true}
            hint={"Attuali: " + intToCurrency(entity.amounts.cashReceivedAmount)} />
        </div>
      </div>
      }
      {
        cashAmount !== null && cashReceivedAmount !== null && cashAmount - cashReceivedAmount > 0 && showCash &&
        <Checkbox checked={useCashToPay} onChange={setUseCashToPay} id="useCashToPay"
          title={"Utilizza i soldi alla mano per pagare il " + (props.type === "driver" ? "fattorino" : "fornitore")} />
      }
    </div>
    {
      canConfirm &&
      <>
        <h4 className="mt-4">Preview</h4>
        <ReportCard
          amounts={props.type === "driver" ? driverAmounts! : supplierAmounts!}
          showCash={props.type === "driver" || supplier.canUseOwnDrivers === true || supplier.responsibleForCash === true}
          useCashToPay={cashAmount! - cashReceivedAmount! > 0 && showCash && useCashToPay}
          type={props.type} />
      </>
    }

  </div>

}

export default withRouter(CreateReportScreen);