import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import NumberFormat from 'react-number-format';
import Supplier from '../../../../models/supplier';

type ChangeSupplierInfoModalProps = {
  initialInfo: Supplier;
  show: boolean;
  close: () => void;
  supplierId: string;
}

function ChangeSupplierInfoModal({ initialInfo, show, close, supplierId }: ChangeSupplierInfoModalProps) {
  const [companyName, setCompanyName] = useState(initialInfo.companyName);
  const [name, setName] = useState(initialInfo.name);
  const [description, setDescription] = useState(initialInfo.description);
  const [registeredOfficeAddress, setRegisteredOfficeAddress] = useState(initialInfo.registeredOfficeAddress ?? "");
  const [phoneNumber, setPhoneNumber] = useState(initialInfo.phoneNumber);
  const [partitaIVA, setPartitaIVA] = useState(initialInfo.partitaIVA);
  const [iban, setIban] = useState(initialInfo.iban);
  const [ipa, setIpa] = useState(initialInfo.ipa ?? "");
  const [notes, setNotes] = useState(initialInfo.notes ?? "");

  useEffect(() => {
    if (show) {
      setCompanyName(initialInfo.companyName);
      setName(initialInfo.name);
      setDescription(initialInfo.description);
      setRegisteredOfficeAddress(initialInfo.registeredOfficeAddress ?? "");
      setPhoneNumber(initialInfo.phoneNumber);
      setPartitaIVA(initialInfo.partitaIVA);
      setIban(initialInfo.iban);
      setIpa(initialInfo.ipa ?? "");
      setNotes(initialInfo.notes ?? "");
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    try {
      await firestore.collection("suppliers").doc(supplierId).update({
        companyName,
        name,
        description,
        registeredOfficeAddress,
        phoneNumber,
        partitaIVA,
        iban,
        ipa,
        notes
      });

      toastr.success("Informazioni fornitore cambiate con successo!", "");
    }
    catch (err) {
      toastr.error("Errore inaspettato!", "");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close} dialogClassName="modal-90w" >
      <form onSubmit={(e) => { e.preventDefault(); save(); }}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica informazioni fornitore</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-inline mt-2" >
            <label className="required" >Ragione sociale</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />
          </div>
          <div className="form-inline mt-2" >
            <label className="required">Nome al pubblico</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
          <div className="form-inline mt-2" >
            <label className="required">Descrizione</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={description} onChange={(e) => setDescription(e.target.value)} required />
          </div>
          <div className="form-inline mt-2" >
            <label>Indirizzo sede legale</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={registeredOfficeAddress} onChange={(e) => setRegisteredOfficeAddress(e.target.value)} />
          </div>
          <div className="form-inline mt-2" >
            <label className="required">Numero di telefono</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
          </div>
          <div className="form-inline mt-2" >
            <label className="required">Partita IVA</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={partitaIVA} onChange={(e) => setPartitaIVA(e.target.value)} required />
          </div>
          <div className="form-inline mt-2" >
            <label className="required">IBAN</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={iban} onChange={(e) => setIban(e.target.value)} required />
          </div>
          <div className="form-inline mt-2" >
            <label>Codice univoco per la fatturazione</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={ipa} onChange={(e) => setIpa(e.target.value)} />
          </div>
          <div className="form-inline mt-2" >
            <label>Note aggiuntive</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={notes} onChange={(e) => setNotes(e.target.value)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={close}>
            Annulla
          </Button>
          <Button variant="success" type="submit">
            Salva
          </Button>
        </Modal.Footer>
      </form>
    </Modal >
  )
}

export default ChangeSupplierInfoModal;
