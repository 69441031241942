import firebase from "firebase";
import moment, { Moment } from "moment";
import Admin from "../models/admin";
import Movement, { MovementType } from "../models/movement";
import DriverReport, { DriverAmounts } from "../models/driver_report";
import SupplierReport, { SupplierAmounts } from "../models/supplier_report";

export const editAdminBalanceToPay = async (adminId: string, amount: number) => {
  let firestore = firebase.firestore();
  let documentRef = firestore.collection("admin").doc(adminId);

  await firestore.runTransaction(async (tx) => {
    let adminSnap = await tx.get(documentRef);
    let admin = adminSnap.data() as Admin;
    let newAmount = Math.round((admin!.balanceToPay - amount) * 100.0) / 100.0;

    await tx.update(documentRef, { balanceToPay: newAmount });
  });
};

export const resetAdminAmount = async (adminId: string, amount: number, field: string) => {
  let firestore = firebase.firestore();
  let documentRef = firestore.collection("admin").doc(adminId);

  await documentRef.update({ [field]: amount });
};

export const createMovement = async (
  entityId: string,
  entityType: "driver" | "supplier",
  movementType: MovementType,
  orderId: string,
  amount: number,
  date: Moment,
  supplierPercentage: number | undefined = undefined,
) => {
  const firestore = firebase.firestore();
  const documentRef = firestore.collection(entityType === "driver" ? "drivers" : "suppliers")
    .doc(entityId)
    .collection("movements").doc();

  const movement: Movement = {
    id: documentRef.id,
    type: movementType,
    ...(orderId.length > 0 ? { orderId } : {}),
    amount: Math.floor(amount * 100),
    date: firebase.firestore.Timestamp.fromMillis(date.valueOf()),
    ...(supplierPercentage ? { supplierPercentage } : {}),
  };

  await documentRef.set(movement);
}

export const editMovement = async (
  entityId: string,
  entityType: "driver" | "supplier",
  movementId: string,
  movementType: MovementType,
  orderId: string,
  amount: number,
  date: Moment,
  supplierPercentage: number | undefined = undefined
) => {
  const firestore = firebase.firestore();
  const documentRef = firestore.collection(entityType === "driver" ? "drivers" : "suppliers")
    .doc(entityId)
    .collection("movements").doc(movementId);

  await documentRef.update({
    type: movementType,
    orderId: orderId.length > 0 ? orderId : null,
    amount: Math.floor(amount * 100),
    date: firebase.firestore.Timestamp.fromMillis(date.valueOf()),
    ...(supplierPercentage ? { supplierPercentage } : {}),
  });
}

export const deleteMovement = async (
  entityId: string,
  entityType: "driver" | "supplier",
  movementId: string) => {
  const firestore = firebase.firestore();
  const documentRef = firestore.collection(entityType === "driver" ? "drivers" : "suppliers")
    .doc(entityId)
    .collection("movements").doc(movementId);

  await documentRef.delete();
}

export const createDriverReport = async (
  driverId: string,
  driverName: string,
  amounts: DriverAmounts,
  useCashToPay: boolean,
): Promise<string> => {
  const datetimeMillis = moment().valueOf();
  const firestore = firebase.firestore();
  const documentRef = firestore.collection("driver_reports").doc(driverId + "_" + datetimeMillis);

  const report: DriverReport = {
    ...amounts,
    id: documentRef.id,
    datetime: firebase.firestore.Timestamp.fromMillis(datetimeMillis),
    useCashToPay,
    driverId: driverId,
    driverName: driverName,
  }

  await documentRef.set(report);

  return documentRef.id;
}

export const createSupplierReport = async (
  supplierId: string,
  supplierName: string,
  amounts: SupplierAmounts,
  useCashToPay: boolean,
): Promise<string> => {
  const datetimeMillis = moment().valueOf();
  const firestore = firebase.firestore();
  const documentRef = firestore.collection("supplier_reports").doc(supplierId + "_" + datetimeMillis);

  const report: SupplierReport = {
    ...amounts,
    id: documentRef.id,
    datetime: firebase.firestore.Timestamp.fromMillis(datetimeMillis),
    useCashToPay,
    supplierId,
    supplierName,
  }

  await documentRef.set(report);

  return documentRef.id;
}

export const deleteDriverReport = async (reportId: string) => {
  const firestore = firebase.firestore();
  await firestore.collection("driver_reports").doc(reportId).delete();
}

export const deleteSupplierReport = async (reportId: string) => {
  const firestore = firebase.firestore();
  await firestore.collection("supplier_reports").doc(reportId).delete();
}