import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import Area from '../models/area';
import { RootState } from '../store/reducers/rootReducer';

type ChangeAreaModalProps = {
  show: boolean;
  onCancel: () => void;
  onDone: (areaId: string) => void;
  currentAreaId: string;
}

function ChangeAreaModal({ show, onCancel, onDone, currentAreaId }: ChangeAreaModalProps) {
  useFirestoreConnect({
    collection: "areas"
  });

  const areas = useSelector((state: RootState) => state.firestore.ordered.areas as Area[]);

  const [areaId, setAreaId] = useState(currentAreaId);

  useEffect(() => {
    if (show)
      setAreaId(currentAreaId);
  }, [show])

  let body: JSX.Element;

  if (!isLoaded(areas))
    body = < div />;
  else
    body = <>
      <div className="form-group">
        <label htmlFor="area" className="required">Area</label>
        <select className="form-control" id="area" value={areaId} required onChange={(e) => setAreaId(e.target.value)} >
          {areas.map((a) => <option value={a.id} key={a.id}>{a.name}</option>)}
        </select>
      </div>
    </>;

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Cambiamento area</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={onCancel}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={() => {
          if (areaId === currentAreaId)
            onCancel();
          else
            onDone(areaId);
        }} disabled={!isLoaded(areas)}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeAreaModal;
