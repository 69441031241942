import React from 'react';
import { Link } from 'react-router-dom';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import Collapsible from 'react-collapsible';
import { RootState } from '../store/reducers/rootReducer';
import Area from '../models/area';
import Admin from '../models/admin';

type AreaSelectViewProps = {
    isExpandable?: boolean;
    urlPrefix: string;
}

function AreaSelectView({ urlPrefix, isExpandable = false }: AreaSelectViewProps) {
    const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);
    useFirestoreConnect({
        collection: 'areas',
        ...!admin.isMainAdmin ? {
            where: ["id", "in", admin.areaIds]
        } : {},
        orderBy: ['name', 'asc'],
    });

    const areas = useSelector((state: RootState) => state.firestore.ordered['areas'] as Area[]);
    if (!isLoaded(areas))
        return <div />;

    let body = areas.map((area) =>
        <div key={area.id} style={{ maxWidth: "500px" }} className="card card-body py-2 mb-1">
            <Link to={"/" + urlPrefix + "/area/" + area.id}>{area.name}</Link>
        </div>
    );

    if (isExpandable === true)
        return <Collapsible transitionTime={200} trigger={
            <div className="d-flex justify-content-between" style={{ maxWidth: "500px", cursor: "pointer" }}>
                <div className="d-flex align-items-center" >
                    <h4 className="mb-0">Aree</h4>
                    <i className="fas fa-chevron-down ml-2 arrow-collapsible" />
                </div>
                <Link to="/areas" className="mt-1">Gestione aree</Link>
            </div>
        }>
            <div className="mt-2" />
            {body}
        </Collapsible>


    return <div className="my-2">
        <div className="d-flex justify-content-between" style={{ maxWidth: "500px" }}>
            <h4>Aree</h4>
            <Link to="/areas" className="mt-1">Gestione aree</Link>
        </div>
        {body}
    </div>;
}

export default AreaSelectView;