import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import NumberFormat from 'react-number-format';

type ChangeCanAddOrdersModalProps = {
  initialCanAddOrders: boolean;
  initialSupplierPercentage: number | undefined | null;
  show: boolean;
  close: () => void;
  supplierId: string;
}

function ChangeCanAddOrdersModal({ initialCanAddOrders, initialSupplierPercentage, show, close, supplierId }: ChangeCanAddOrdersModalProps) {
  const [canAddOrders, setCanAddOrders] = useState(initialCanAddOrders);
  const [supplierPercentage, setSupplierPercentage] = useState<number | undefined>(initialSupplierPercentage ?? undefined);


  useEffect(() => {
    if (show) {
      setCanAddOrders(initialCanAddOrders);
      setSupplierPercentage(initialSupplierPercentage ?? undefined);
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    try {
      await firestore.collection("suppliers").doc(supplierId).update({
        canAddOrders,
        supplierPercentageOrdersCreatedBySupplier: supplierPercentage ?? null,
      });

      toastr.success("", "Impostazione cambiata con successo!");
    }
    catch (err) {
      toastr.error("", "Errore inaspettato!");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Aggiunta di ordini da parte del fornitore</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id="canAddOrders"
            checked={canAddOrders} onChange={(e) => setCanAddOrders(e.target.checked)} />
          <label className="custom-control-label" htmlFor="canAddOrders">Il fornitore può inserire ordini dalla piattaforma T-Shop </label>
        </div>
        {
          canAddOrders &&
          <div className="form-group mt-4">
            <label>Percentuale fornitore ordini aggiunti da gestionale: </label>
            <NumberFormat
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              suffix="%"
              fixedDecimalScale={true}
              className="form-control"
              value={supplierPercentage === undefined ? undefined : supplierPercentage * 100.}
              isAllowed={(values) => {
                const { floatValue } = values;
                if (floatValue === undefined)
                  return true;
                return floatValue > 0 && floatValue <= 100;
              }}
              onValueChange={({ floatValue }) => {
                if (floatValue !== supplierPercentage)
                  setSupplierPercentage(floatValue === undefined ? undefined : floatValue / 100.)
              }} required />
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={save}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeCanAddOrdersModal;
