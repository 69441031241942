import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

class CreateTagModal extends Component {
  state = { name: "" }

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onSave(this.state.name);
    this.setState({ name: "" });
  }

  render() {
    const { show } = this.props;
    return (
      <Modal show={show} onHide={this.props.onCancel}>
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Crea nuovo tag</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="required">Nome</label>
              <input className="form-control mr-3" type="text" onChange={this.handleNameChange} value={this.state.name} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" type="button" onClick={this.props.onCancel}>
              Annulla
            </Button>
            <Button variant="success" type="submit">
              Salva
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default CreateTagModal;
