import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import moment from 'moment';
import NumberFormat from 'react-number-format';

class WeekdayConfig extends Component {

  toggleUnlimited = (time, unlimited) => {
    let maxDrivers = unlimited ? -1 : 0;
    this.props.onChange(time, maxDrivers);
  }

  render() {
    const { weekdayNumber, data, shiftLength } = this.props;

    let weekdayName = moment.weekdays(true)[weekdayNumber - 1];
    weekdayName = weekdayName.charAt(0).toUpperCase() + weekdayName.slice(1);

    let splitCount = 6;

    let splitComponents = [];

    for (let i = 0; i < splitCount; i++) {
      let start = moment("00:00", "HH:mm").add(24 * i / splitCount, "hours");
      let end = moment("00:00", "HH:mm").add(24 * (i + 1) / splitCount, "hours").subtract(shiftLength, "minutes");
      let startTimeComponents = [];
      let maxDriversComponents = [];
      let unlimitedComponents = [];
      for (var curr = start.clone(); curr.isSameOrBefore(end); curr.add(shiftLength, "minutes")) {
        let timeString = curr.format("HH:mm");
        startTimeComponents.push(<td key={"time" + timeString}>{timeString}</td>);
        let unlimited = data[timeString] === -1;
        maxDriversComponents.push(<td key={"maxDrivers" + timeString} className={unlimited ? "disabled" : ""} onClick={unlimited ? () => this.toggleUnlimited(timeString, !unlimited) : () => { }}>
          {unlimited ? "" :
            <NumberFormat
              decimalScale={0}
              defaultValue={0}
              allowNegative={false}
              thousandSeparator={false}
              className="input-number"
              value={data[timeString]}
              size={4}
              onValueChange={({ floatValue }) => {
                if (floatValue !== undefined) {
                  this.props.onChange(timeString, floatValue);
                }
              }} required />
          }
        </td>)
        unlimitedComponents.push(
          <td key={"unlimited" + timeString} className={"unlimited" + (unlimited ? "" : " unchecked")} onClick={() => this.toggleUnlimited(timeString, !unlimited)}>
            {unlimited ? <i className="fas fa-check"></i> : ""}
          </td>
        )
      }
      let component = (
        <div className="mt-4 split" key={"split" + i}>
          <div>Turni dalle {start.format("HH:mm")} alle {end.format("HH:mm")}</div>
          <div className="table-container">
            <table className="table table-bordered mt-2 mb-1">
              <tbody>
                <tr>
                  <th scope="row" style={{ whiteSpace: "nowrap" }} className="fixed-side">Inizio turno</th>
                  {startTimeComponents}
                </tr>
                <tr>
                  <th scope="row" style={{ whiteSpace: "nowrap" }} className="fixed-side">N° massimo fattorini</th>
                  {maxDriversComponents}
                </tr>
                <tr>
                  <th scope="row" style={{ whiteSpace: "nowrap" }} className="fixed-side">N° illimitato</th>
                  {unlimitedComponents}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );

      splitComponents.push(component);
    }

    return <div className="my-1 card card-body py-2">
      <Collapsible transitionTime={200} trigger={
        <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
          <b>
            {weekdayName}
          </b>
          <i className="fas fa-chevron-down ml-2 arrow-collapsible" />
        </div>}>
        {splitComponents}

      </Collapsible>
    </div>
  }
}

export default WeekdayConfig;