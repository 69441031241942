import React from 'react'
import {
    Route,
    Redirect
} from 'react-router-dom';
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

function NonAdminRoute(props) {
    const {children,...rest} = props;
    const { auth, profile } = useSelector(state => ({ auth: state.firebase.auth, profile: state.firebase.profile }));

    const isAdmin = !isEmpty(profile) && isLoaded(profile) ? profile.token.claims.admin === true : false;
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (isLoaded(auth) && !isEmpty(auth)) {
                    if(isAdmin)
                        return (<Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />);
                    else
                        return children;
                }
                else {
                    return (<Redirect
                        to={{
                            pathname: "/signin",
                            state: { from: location }
                        }}
                    />);
                }
            }
            }
        />
    );
}

export default NonAdminRoute;