import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import SupplierList from './components/SupplierList';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import SplashScreen from '../SplashScreen';

class SupplierCategoryScreen extends Component {
  render() {
    let { categories, area, admin } = this.props;

    if (!isLoaded(categories) || !isLoaded(area))
      return <SplashScreen />;

    if (!admin.isMainAdmin && !admin.areaIds.includes(area.id))
      return <div className="container my-4">Non hai accesso alla visualizzazione di questa pagina.</div>;

    let categoryId = this.props.match.params.categoryId;

    let category = categories?.[categoryId];

    if (!category)
      return <p className="container my-4">Categoria inesistente</p>;

    return <div className="container my-4">
      <h3>Area &quot;{area.name}&quot;</h3>
      <SupplierList category={category} areaId={this.props.match.params.areaId} />
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.firestore.data['supplier_categories'],
    area: state.firestore.data.area,
    admin: state.firestore.data.currentAdmin,
  }
}

export default withRouter(compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    return [
      {
        collection: 'supplier_categories',
      }, {
        collection: 'areas',
        doc: props.match.params.areaId,
        storeAs: 'area',
      },
    ];
  })
)(SupplierCategoryScreen))