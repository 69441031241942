import firebase from 'firebase/app'
import OrderProduct from './order_product';
import OrderStates from './order_states';

type Order = {
  products: OrderProduct[];
  productCount: number;
  cartAmount: number;
  deliveryAmount: number;
  points?: number;
  distance: number;
  notes: string;

  supplierPercentage: number;
  driverAmount: number;
  adminPercentage: number;

  state: OrderStates;
  hasReview?: boolean;

  refusalReason?: string;

  // Timestamps
  creationTimestamp: firebase.firestore.Timestamp;
  postponeTimestamp: firebase.firestore.Timestamp;
  acceptanceTimestamp: firebase.firestore.Timestamp;
  shiftStartTime: firebase.firestore.Timestamp;
  preferredDeliveryTimestamp: firebase.firestore.Timestamp;
  modificationTimestamp: firebase.firestore.Timestamp;
  cancellationTimestamp: firebase.firestore.Timestamp;
  readyTimestamp: firebase.firestore.Timestamp;
  pickupTimestamp: firebase.firestore.Timestamp;
  deliveryTimestamp: firebase.firestore.Timestamp;
  refusalTimestamp: firebase.firestore.Timestamp;
  archiviationTimestamp: firebase.firestore.Timestamp;

  adminId: string | undefined;

  // Customer info
  customerId: string;
  customerName: string;
  customerCoordinates: firebase.firestore.GeoPoint;
  hasCustomerCoordinates: boolean;
  customerAddress: string;
  customerStreetNumber: string;
  customerPhoneNumber: string;
  customerImageUrl: string;
  paymentIntentId: string;
  useCashPayment?: boolean;
  cashAmount: number;

  // Supplier info
  supplierId: string;
  supplierName: string;
  supplierCoordinates: firebase.firestore.GeoPoint;
  supplierAddress: string;
  supplierPhoneNumber: string;
  supplierImageUrl: string;

  // Driver info
  useOwnDrivers: boolean | undefined;
  driverId: string;
  driverName: string;
  driverPhoneNumber: string;
  driverImageUrl: string;

  // Sconto
  discountCodeId: string;
  discountCode: string;
  discountAmount: number;
  discountPercentage: number;

  areaId: string;

  driverSeen?: boolean;

  postponeRequested?: boolean;
  isPreponed?: boolean;

  visualized?: boolean;
  stateChangedByAdmin?: boolean;

  balanceUpdated?: boolean;
  reference: firebase.firestore.DocumentReference;

  moreProductsRequested: boolean | undefined;
  moreProductsRequest: MoreProductRequest | undefined;
  moreProductsRequestAccepted: boolean | undefined;

  createdBySupplier: boolean | undefined;
  supplierResponsibleForCash: boolean | undefined;
}

function getPlatformAmountForOrder(order: Order): number {
  const driverAmount = order.useOwnDrivers ? 0 : order.driverAmount;
  const supplierAmount = getSupplierAmountForOrder(order).amountWithoutIVA;
  return order.cartAmount + order.deliveryAmount - driverAmount - supplierAmount - (order.discountAmount ?? 0);
}

function getSupplierAmountForOrder(order: Order): SupplierAmountForOrder {
  const deliveryAmount = order.useOwnDrivers === true ? order.deliveryAmount : 0;
  return {
    amountWithoutIVA: order.cartAmount * order.supplierPercentage + deliveryAmount,
    amountWithIVA: order.cartAmount * (1.22 * order.supplierPercentage - 0.22) + deliveryAmount,
  };
}

type SupplierAmountForOrder = {
  amountWithoutIVA: number;
  amountWithIVA: number;
}
export { getPlatformAmountForOrder, getSupplierAmountForOrder };

export type MoreProductRequest = {
  products: OrderProduct[];
  productCount: number;
  cartAmount: number;
  timestamp: firebase.firestore.Timestamp;
  paymentIntentId: string | null;
}

export default Order;