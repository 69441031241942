import React, { Component } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { compose } from 'redux'
import { getDateTimeString } from '../../../helpers/dateHelper';
import { withRouter } from 'react-router-dom';
import { updateRequestState } from '../../../store/actions/driverActions'
import ClickableImage from '../../../components/ClickableImage';
import ButtonWithConfirmationAndTextArea from '../../../components/ButtonWithConfirmationAndTextArea';
import ButtonWithConfirmation from '../../../components/ButtonWithConfirmation';
import SplashScreen from '../../SplashScreen';

class DriverRequestDetailsScreen extends Component {
  handleRefuse = (reason) => {
    this.props.refuseDriverRequest(reason);
    this.props.history.push('/drivers');
  }

  handleAccept = () => {
    this.props.acceptDriverRequest();
    this.props.history.push('/drivers');
  }

  render() {
    let { request, admin, area } = this.props;

    if (!isLoaded(request, area))
      return <SplashScreen />

    if (isEmpty(request) || request?.state !== "PENDING")
      return <div className="container my-4">Richiesta inesistente.</div>;

    if (!admin.isMainAdmin && !admin.areaIds.includes(request.areaId))
      return <div className="container my-4">Non hai accesso alla visualizzazione di questa richiesta.</div>;

    return (
      <div className="container my-4">
        <h4 className="my-auto">Richiesta di collaborazione fattorino</h4>
        <div className="card mt-2">
          <div className="card-body">
            <h5 className="mt-2">Informazioni personali</h5>
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <th scope="row">Data richiesta: </th>
                  <td style={{ whiteSpace: "nowrap", width: "80%" }}>{getDateTimeString(request.timestamp)}</td>
                </tr>
                <tr>
                  <th scope="row">Nome e cognome fattorino: </th>
                  <td>{request.name}</td>
                </tr>
                <tr>
                  <th scope="row">Codice fiscale: </th>
                  <td>{request.fiscalCode}</td>
                </tr>
                <tr>
                  <th scope="row">Immagine di profilo: </th>
                  <td>
                    <ClickableImage url={request.imageUrl} style={{ maxWidth: "100%", maxHeight: "200px" }} />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Documento d&apos;identità: </th>
                  <td className="d-flex">
                    <ClickableImage url={request.IDCardFrontUrl} style={{ maxWidth: "100%", maxHeight: "200px" }} />
                    <div className="mx-3" />
                    <ClickableImage url={request.IDCardBackUrl} style={{ maxWidth: "100%", maxHeight: "200px" }} />
                  </td>
                </tr>
                <tr>
                  <th scope="row">Descrizione personale: </th>
                  <td>{request.description}</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h5 className="mt-2">Residenza</h5>
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <th scope="row">Area: </th>
                  <td style={{ width: "80%" }}>{area.name ?? "Non specificato"}</td>
                </tr>
                <tr>
                  <th scope="row">Indirizzo: </th>
                  <td style={{ width: "80%" }}>{request.address}</td>
                </tr>
                <tr>
                  <th scope="row">Numero di telefono: </th>
                  <td style={{ whiteSpace: "nowrap" }}>{request.phoneNumber}</td>
                </tr>
                <tr>
                  <th scope="row">Mezzi di trasporto a disposizione: </th>
                  <td>{request.meansOfTransport}</td>
                </tr>
              </tbody>
            </table>
            <div className="mt-2 d-flex justify-content-end">
              <ButtonWithConfirmationAndTextArea
                textAreaTitle="Motivazione"
                modalMessage="Sicuro di voler rifiutare la richiesta?"
                className="btn btn-outline-danger btn-sm mr-1"
                onConfirm={this.handleRefuse} buttonText="Rifiuta" />
              <ButtonWithConfirmation modalMessage="Sicuro di voler accettare la richiesta?"
                className="btn btn-outline-success btn-sm"
                onConfirm={this.handleAccept}
                buttonText="Accetta" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    request: state.firestore.data.request,
    admin: state.firestore.data.currentAdmin,
    area: state.firestore.data.requestArea,
  }
}
const mapDispatchToProps = (dispatch, props) => {
  return {
    acceptDriverRequest: () => dispatch(updateRequestState(props.match.params.id, "ACCEPTED")),
    refuseDriverRequest: (reason) => dispatch(updateRequestState(props.match.params.id, "REFUSED", reason)),
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    console.log(props)
    return [
      {
        collection: 'driver_requests',
        doc: props.match.params.id,
        storeAs: 'request',
      },
      ...props.request !== null && props.request !== undefined ? [
        {
          collection: "areas",
          doc: props.request.areaId,
          storeAs: "requestArea"
        }
      ] : [],
    ];
  }),
)(DriverRequestDetailsScreen))