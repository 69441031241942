import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import { RouteComponentProps, withRouter } from 'react-router-dom'
import OrderHistory from '../../orders/OrderHistory';
import { toggleDisabledDriver } from '../../../store/actions/driverActions'
import { Link } from 'react-router-dom';
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import ChangeCashPaymentOrdersModal from './components/ChangeCashPaymentOrdersModal';
import { RootState } from '../../../store/reducers/rootReducer';
import Driver from '../../../models/driver';
import DriverPosition from '../../../models/driver_position';
import Admin from '../../../models/admin';
import { useAppDispatch } from '../../../store/store';
import ChangeDriverInfoModal from './components/ChangeDriverInfoModal';
import Area from '../../../models/area';
import { getDriverCompleteAmounts } from '../../../models/driver_report';
import { createMovement } from '../../../services/firestore_service';
import { intToCurrency } from '../../../helpers/currencyHelper';
import moment from 'moment';
import BalancePaidModal from '../../../components/BalancePaidModal';
import ChangeAreaModal from '../../../components/ChangeAreaModal';
import ChangeEmailModal from '../../../components/ChangeEmailModal';
import ChangePasswordModal from '../../../components/ChangePasswordModal';
import ClickableImage from '../../../components/ClickableImage';
import ResetAmountModal from '../../../components/ResetAmountModal';
import SplashScreen from '../../SplashScreen';

function DriverDetailsScreen(props: RouteComponentProps<{ id: string }, any, any>) {
  const { driver, driverPosition, admin, area } = useSelector((state: RootState) => ({
    driver: state.firestore.data.drivers?.[props.match.params.id] as Driver,
    driverPosition: state.firestore.data.driver_positions?.[props.match.params.id] as DriverPosition,
    admin: state.firestore.data.currentAdmin as Admin,
    area: state.firestore.data.area as Area,
  }));

  useFirestoreConnect(() => [
    {
      collection: 'drivers',
      doc: props.match.params.id,
    },
    {
      collection: 'driver_positions',
      doc: props.match.params.id,
    },
    ...isLoaded(driver) ? [{
      collection: "areas",
      doc: driver.areaId,
      storeAs: "area"
    }] : [],
  ]);

  const dispatch = useAppDispatch();

  const disableDriver = () => dispatch(toggleDisabledDriver(props.match.params.id, true));
  const enableDriver = () => dispatch(toggleDisabledDriver(props.match.params.id, false));

  const [showBalancePaidModal, setShowBalancePaidModal] = useState(false);
  const [showResetBalanceModal, setShowResetBalanceModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showCashModal, setShowCashModal] = useState(false);
  const [showResetCashModal, setShowResetCashModal] = useState(false);
  const [showChangeCashPaymentOrdersModal, setShowChangeCashPaymentOrdersModal] = useState(false);
  const [showChangeInfoModal, setShowChangeInfoModal] = useState(false);
  const [showChangeAreaModal, setShowChangeAreaModal] = useState(false);

  if (!isLoaded(driver, area))
    return <SplashScreen />

  if (isEmpty(driver))
    return <div className="container my-4">Fattorino inesistente.</div>;

  if (!admin.isMainAdmin && !admin.areaIds!.includes(driver.areaId))
    return <div className="container my-4">Non hai accesso alla visualizzazione di questo fattorino.</div>;

  const amounts = getDriverCompleteAmounts(driver.amounts);
  const totalAmounts = getDriverCompleteAmounts(driver.totalAmounts);

  return (
    <div className="driverDetails container my-4">
      <div className="d-flex justify-content-between">
        <h4 className="my-auto">Dettaglio fattorino</h4>
        <div className="d-flex">
          <Link to={"/drivers/" + props.match.params.id + "/reports"} className="btn btn-outline-primary">
            Visualizza entrate
          </Link>
          {driver.disabled === true &&
            <button className="btn btn-outline-success ml-2" onClick={enableDriver}>Riabilita</button>}
          {driver.disabled !== true &&
            <button className="btn btn-outline-danger ml-2" onClick={disableDriver}>Disabilita</button>}
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <div className="d-flex flex-wrap">
            <div className="my-auto">
              <img src={driver.imageUrl} className="rounded mr-2 d-block" style={{ maxWidth: "200px", height: "auto" }}></img>
            </div>
            <div className="d-flex flex-column flex-fill">
              <div className="flex-grow-1">
                <table className="my-auto table table-borderless table-sm">
                  <tbody>
                    <tr>
                      <th scope="row" style={{ verticalAlign: "middle" }}>ID Fattorino: </th>
                      <td>
                        <div className="d-flex align-items-center">
                          <div>{driver.uid}</div>
                          <button type="button" className="btn btn-info ml-2" onClick={() => {
                            navigator.clipboard.writeText(driver.uid);
                            toastr.info("ID del fattorino copiato con successo!", "");
                          }} ><i className="fas fa-copy"></i></button>
                        </div>
                      </td>
                    </tr>
                    <InfoRow title="Area" value={area.name} onEdit={() => setShowChangeAreaModal(true)} />
                    <ChangeAreaModal show={showChangeAreaModal} onCancel={() => setShowChangeAreaModal(false)}
                      currentAreaId={driver.areaId} onDone={async (newAreaId) => {
                        setShowChangeAreaModal(false)
                        if (newAreaId === driver.areaId)
                          return;
                        const functions = firebase.functions();

                        try {
                          const changeDriverArea = functions.httpsCallable("changeDriverArea");
                          const response = await changeDriverArea({ driverId: driver.uid, newAreaId });
                          if (response.data.success === false) {
                            toastr.error("Errore: " + response.data.error, "")
                          }
                          else {
                            toastr.success("Area del fattorino cambiata con successo!", "")
                          }
                        }
                        catch (err) {
                          console.log(err);
                          toastr.error("Errore inaspettato!", "");
                        }
                      }} />
                    <InfoRow title="Indirizzo email" value={driver.email} />
                    <InfoRow title="Nome e cognome" value={driver.nominative} onEdit={() => setShowChangeInfoModal(true)} />
                    <InfoRow title="Codice fiscale" value={driver.fiscalCode} onEdit={() => setShowChangeInfoModal(true)} />
                    <InfoRow title="Indirizzo" value={driver.address ?? ""} />
                    <InfoRow title="Numero di telefono" value={driver.phoneNumber} onEdit={() => setShowChangeInfoModal(true)} />
                    <InfoRow title="IBAN" value={driver.iban} onEdit={() => setShowChangeInfoModal(true)} />
                    <ChangeDriverInfoModal initialInfo={driver} driverId={props.match.params.id}
                      close={() => setShowChangeInfoModal(false)} show={showChangeInfoModal} />
                    {(driver.IDCardFrontUrl || driver.IDCardBackUrl) &&
                      <tr>
                        <th scope="row">Documento d&apos;identità: </th>
                        <td className="d-flex">
                          <ClickableImage url={driver.IDCardFrontUrl} style={{ maxWidth: "100%", maxHeight: "200px" }} />
                          <div className="mx-3" />
                          <ClickableImage url={driver.IDCardBackUrl} style={{ maxWidth: "100%", maxHeight: "200px" }} />
                        </td>
                      </tr>
                    }

                  </tbody>
                </table>
                <hr />
                <table className="table table-borderless table-sm">
                  <tbody>
                    <InfoRow title="Pagamenti con contanti abilitati" value={driver.canAcceptOrdersWithCashPayment === true ? "Sì" : "No"}
                      onEdit={() => setShowChangeCashPaymentOrdersModal(true)} />
                    <ChangeCashPaymentOrdersModal close={() => setShowChangeCashPaymentOrdersModal(false)}
                      show={showChangeCashPaymentOrdersModal} driverId={props.match.params.id}
                      initialCanAcceptOrdersWithCashPayment={driver.canAcceptOrdersWithCashPayment === true} />
                  </tbody>
                </table>
              </div>
              <div className="d-flex mt-2 ml-1">
                <Link to="#" onClick={() => setShowChangePasswordModal(true)}>Cambia password</Link>
                <ChangePasswordModal onCancel={() => setShowChangePasswordModal(false)} onDone={async (password) => {
                  setShowChangePasswordModal(false)
                  const functions = firebase.functions();

                  try {
                    const changeDriverPassword = functions.httpsCallable("changeDriverPassword");
                    const response = await changeDriverPassword({ driverId: driver.uid, password });
                    if (response.data.success === false) {
                      toastr.error("Errore: " + response.data.error, "")
                    }
                    else {
                      toastr.success("Password del fattorino cambiata con successo!", "")
                    }
                  }
                  catch (err) {
                    console.log(err);
                    toastr.error("Errore inaspettato!", "");
                  }
                }} show={showChangePasswordModal} />
                <Link to="#" onClick={() => setShowChangeEmailModal(true)} className="ml-3">Cambia email</Link>
                <ChangeEmailModal onCancel={() => setShowChangeEmailModal(false)} onDone={async (email) => {
                  setShowChangeEmailModal(false)
                  const functions = firebase.functions();

                  try {
                    const changeDriverEmail = functions.httpsCallable("changeDriverEmail");
                    const response = await changeDriverEmail({ driverId: driver.uid, email });
                    if (response.data.success === false) {
                      toastr.error("Errore: " + response.data.error, "")
                    }
                    else {
                      toastr.success("Email del fattorino cambiata con successo!", "")
                    }
                  }
                  catch (err) {
                    console.log(err);
                    toastr.error("Errore inaspettato!", "");
                  }
                }} show={showChangeEmailModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h4 className="mt-3">Saldo</h4>
      <div className="card card-body">
        <div className="mt-1">Saldo totale: <b>{intToCurrency(totalAmounts.totalAmount)}</b></div>
        <div className="mt-1">Saldo da pagare: <b>{intToCurrency(amounts.totalAmount)}</b></div>

        <div className="d-flex justify-content-start">
          <button className="btn btn-outline-primary mt-2" onClick={() => setShowBalancePaidModal(true)}>Pagato</button>
          <BalancePaidModal show={showBalancePaidModal} onCancel={() => setShowBalancePaidModal(false)}
            onSave={async (amount) => {
              try {
                await createMovement(props.match.params.id, "driver", "DRIVER_ORDER", "", -amount, moment());
              }
              catch (err) {
                console.log(err);
                toastr.error("Errore inaspettato!", "");
              }
              setShowBalancePaidModal(false);
            }} maxAmount={(amounts.totalAmount / 100.0) ?? 0} />

          <button className="btn btn-outline-warning ml-2 mt-2" onClick={() => setShowResetBalanceModal(true)}>Reimposta</button>
          <ResetAmountModal show={showResetBalanceModal} onCancel={() => setShowResetBalanceModal(false)}
            onSave={async (amount) => {
              try {
                await createMovement(props.match.params.id, "driver", "DRIVER_ORDER", "", amount - amounts.totalAmount / 100.0, moment());
              }
              catch (err) {
                console.log(err);
                toastr.error("Errore inaspettato!", "");
              }
              setShowResetBalanceModal(false);
            }} />
        </div>
      </div>
      <h4 className="mt-3">Soldi alla mano</h4>
      <div className="card card-body">
        <div className="mt-1">Soldi alla mano totali: <b>{intToCurrency(totalAmounts.cashAmount)}</b></div>
        <div className="mt-1">Soldi alla mano depositati totali: <b>{intToCurrency(totalAmounts.cashReceivedAmount)}</b></div>
        <div className="mt-1">Saldi alla mano non pagati: <b>{intToCurrency(amounts.cashDiffAmount)}</b></div>

        <div className="d-flex justify-content-start">
          <button className="btn btn-outline-primary mt-2" onClick={() => setShowCashModal(true)}>Pagato</button>
          <BalancePaidModal show={showCashModal} onCancel={() => setShowCashModal(false)} driverCash={true}
            onSave={async (amount) => {
              try {
                await createMovement(props.match.params.id, "driver", "CASH_RECEIVED", "", amount, moment());
              }
              catch (err) {
                console.log(err);
                toastr.error("Errore inaspettato!", "");
              }
              setShowCashModal(false);
            }} maxAmount={(amounts.cashDiffAmount / 100.0) ?? 0} />

          <button className="btn btn-outline-warning ml-2 mt-2" onClick={() => setShowResetCashModal(true)}>Reimposta</button>
          <ResetAmountModal show={showResetCashModal} onCancel={() => setShowResetCashModal(false)}
            onSave={async (amount) => {
              let actualAmount = Math.floor(amount * 100.0);
              try {
                if (actualAmount > amounts.cashDiffAmount)
                  await createMovement(props.match.params.id, "driver", "CASH", "", (actualAmount - amounts.cashDiffAmount) / 100.0, moment());
                else if (actualAmount < amounts.cashDiffAmount)
                  await createMovement(props.match.params.id, "driver", "CASH_RECEIVED", "", (amounts.cashDiffAmount - amount) / 100.0, moment());
              }
              catch (err) {
                console.log(err);
                toastr.error("Errore inaspettato!", "");
              }
              setShowResetCashModal(false);
            }} />
        </div>
      </div>
      { /*
        driverPosition && <>
          <h4 className="mt-3">Mappa</h4>
          <div style={{ height: 400, width: "100%" }}>
            <MapWidget drivers={[{
              driver,
              position: driverPosition
            }]} />
          </div>
        </>*/
      }
      <div className="my-3">
        <OrderHistory driverId={props.match.params.id} viewMode='driver' />
      </div>
    </div>
  )
}

type InfoRowProps = {
  title: string;
  value: string;
  onEdit?: () => void;
}

function InfoRow({ onEdit, title, value }: InfoRowProps) {
  return (
    <tr>
      <th scope="row">{title}</th>
      <td style={{ width: "60%" }}>
        <div style={{ display: "flex" }}>
          <div className="mr-3">{value}</div>
          {onEdit && <i className="fas fa-pen highlight-on-hover" onClick={onEdit} />}
        </div>
      </td>
    </tr>
  );
}

export default withRouter(DriverDetailsScreen);
