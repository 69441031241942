import React from "react";
import { getDriverCompleteAmounts, DriverAmounts, DriverCompleteAmounts } from "../../../models/driver_report";
import { intToCurrency, intToCurrencyWithSign } from "../../../helpers/currencyHelper";
import { getSupplierCompleteAmounts, SupplierAmounts, SupplierCompleteAmounts } from "../../../models/supplier_report";

type AmountsCardProps = {
  amounts: DriverAmounts | SupplierAmounts;
  showCash?: boolean;
  otherComponents?: JSX.Element,
  entityType: "supplier" | "driver"
}

function AmountsCard({ amounts, otherComponents, entityType, showCash = true }: AmountsCardProps) {
  let completeAmounts: DriverCompleteAmounts | SupplierCompleteAmounts;

  if (entityType === "driver") completeAmounts = getDriverCompleteAmounts(amounts as DriverAmounts);
  else completeAmounts = getSupplierCompleteAmounts(amounts as SupplierAmounts);

  return <div className="card card-body">
    <div className="d-flex justify-content-between flex-wrap">
      <table className="table table-borderless table-sm" style={{ fontSize: 16, maxWidth: 400, minWidth: 200 }}>
        <tbody>
          {
            entityType === "supplier" && <>
              <AmountsRow title="Totale ordini" value={intToCurrency((amounts as SupplierAmounts).totalOrderAmount)} />
              <AmountsRow title="Guadagno personale (IVA esclusa)" value={intToCurrency((amounts as SupplierAmounts).orderAmountNoIva)} />
            </>
          }
          <AmountsRow title={entityType === "driver" ? "Entrate ordini" : "Guadagno personale (IVA inclusa)"} value={intToCurrencyWithSign(amounts.orderAmount)} color="green" />
          {
            entityType === "driver" &&
            <AmountsRow title="Bonus" value={intToCurrencyWithSign((amounts as DriverAmounts).bonusAmount)} color="green" />
          }
          <AmountsRow title="Altro" value={intToCurrencyWithSign(amounts.otherAmount)} color="green" />
          <AmountsRow title="Totale entrate" value={intToCurrencyWithSign(completeAmounts.totalAmount)} color="green" />
        </tbody>
      </table>
      <table className="table table-borderless table-sm" style={{ fontSize: 16, maxWidth: 400, minWidth: 200 }}>
        <tbody>
          {showCash && <>
            <AmountsRow title="Contanti accumulati" value={intToCurrency(amounts.cashAmount)} />
            <AmountsRow title="Contanti ricevuti" value={intToCurrency(amounts.cashReceivedAmount)} />
            <AmountsRow title="Differenza contanti" value={intToCurrency(completeAmounts.cashDiffAmount)} />
          </>}
        </tbody>
      </table>
    </div>
    {otherComponents}
  </div>
}

function AmountsRow({ title, value, color }: { title: string, value: string, color?: string }) {
  return <tr >
    <td>{title}</td>
    <td style={{ color: color, fontSize: 18 }}>{value}</td>
  </tr>;
}

export default AmountsCard;

