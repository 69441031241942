import React from 'react';

type CheckboxProps = {
  checked: boolean;
  onChange: (newVal: boolean) => void;
  title: string;
  id: string;
  disabled?: boolean;
  required?: boolean;
}

function Checkbox({ checked, title, onChange, id, disabled = false, required = false }: CheckboxProps) {
  return <div className="custom-control custom-checkbox">
    <input type="checkbox" className="custom-control-input" id={id} checked={checked} onChange={(e) => onChange(e.target.checked)} disabled={disabled} required={required} />
    <label className="custom-control-label" htmlFor={id}>{title}</label>
  </div>
}

export default Checkbox;
