import React, { Component } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { compose } from 'redux'
import { getDateTimeString } from '../../../../helpers/dateHelper';
import { Link } from 'react-router-dom';

class DriverRequests extends Component {
  render() {
    let { driverRequests, areas } = this.props;

    if (!isLoaded(driverRequests, areas) || isEmpty(driverRequests))
      return <div />;

    let requestComponents = driverRequests.map((request) => {
      const areaName = areas.find((a) => a.id === request.areaId)?.name;
      return (
        <div key={request.reference.id} className="my-3 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <div><strong>Area:</strong> {areaName ?? "Non specificato"}</div>
              <div><strong>Data richiesta: </strong>{getDateTimeString(request.timestamp)}</div>
              <div><strong>Nome fattorino:</strong> {request.name}</div>
              <Link to={"/drivers/requests/" + request.reference.id} className="btn btn-outline-primary mt-2">
                Visualizza richiesta
              </Link>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="driverRequests">
        <h4 className="my-auto">Richieste di collaborazione dai fattorini</h4>
        <div className="row">
          {requestComponents}
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    driverRequests: state.firestore.ordered['driverRequests'],
    areas: state.firestore.ordered.areas,
    admin: state.firestore.data.currentAdmin
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'driver_requests',
        orderBy: [['timestamp', 'desc']],
        where: [
          ['state', '==', "PENDING"],
          ...!props.admin.isMainAdmin ? [
            ["areaId", "in", props.admin.areaIds]
          ] : [],
        ],
        storeAs: 'driverRequests',
      },
      {
        collection: "areas",
      }
    ];
  }),
)(DriverRequests)