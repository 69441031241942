import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import Driver from '../../../../models/driver';
import SingleImageUploader from '../../../../components/image_uploader/SingleImageUploader';

type ChangeDriverInfoModalProps = {
  initialInfo: Driver;
  show: boolean;
  close: () => void;
  driverId: string;
}

function ChangeDriverInfoModal({ initialInfo, show, close, driverId }: ChangeDriverInfoModalProps) {
  const [nominative, setNominative] = useState(initialInfo.nominative);
  const [fiscalCode, setFiscalCode] = useState(initialInfo.fiscalCode);
  const [phoneNumber, setPhoneNumber] = useState(initialInfo.phoneNumber);
  const [iban, setIban] = useState(initialInfo.iban);
  const [imageFile, setImageFile] = useState<File | null>(null);

  useEffect(() => {
    if (show) {
      setNominative(initialInfo.nominative);
      setFiscalCode(initialInfo.fiscalCode);
      setPhoneNumber(initialInfo.phoneNumber);
      setIban(initialInfo.iban);
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();
    const storage = firebase.storage();

    try {
      let imageUrl: string | null = null;

      if (imageFile !== null) {
        const storageRef = storage.ref().child('drivers/' + driverId);
        await storageRef.child(imageFile.name).put(imageFile);
        imageUrl = await storageRef.child(imageFile.name).getDownloadURL();
      }

      await firestore.collection("drivers").doc(driverId).update({
        nominative,
        fiscalCode,
        phoneNumber,
        iban,
        ...imageUrl !== null && { imageUrl },
      });

      toastr.success("Informazioni fattorino cambiate con successo!", "");
    }
    catch (err) {
      toastr.error("Errore inaspettato!", "");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close} dialogClassName="modal-90w" >
      <form onSubmit={(e) => { e.preventDefault(); save(); }}>
        <Modal.Header closeButton>
          <Modal.Title>Modifica informazioni fattorino</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-inline mt-2" >
            <label className="required" >Nome e cognome</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={nominative} onChange={(e) => setNominative(e.target.value)} required />
          </div>
          <div className="form-inline mt-2" >
            <label className="required">Codice fiscale</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={fiscalCode} onChange={(e) => setFiscalCode(e.target.value)} required />
          </div>
          <div className="form-inline mt-2" >
            <label className="required">Numero di telefono</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
          </div>
          <div className="form-inline mt-2" >
            <label className="required">IBAN</label>
            <input type="text" style={{ minWidth: "400px" }} className="form-control ml-3" value={iban} onChange={(e) => setIban(e.target.value)} required />
          </div>
          <div className="mt-2" />
          <SingleImageUploader required={true} title="Immagine di profilo" onImageChange={(image: File) => setImageFile(image)}
            defaultImage={initialInfo.imageUrl} hideDeleteButton={true} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={close}>
            Annulla
          </Button>
          <Button variant="success" type="submit">
            Salva
          </Button>
        </Modal.Footer>
      </form>
    </Modal >
  )
}

export default ChangeDriverInfoModal;
