import React from 'react';
import { Link } from 'react-router-dom';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getDateString } from '../../helpers/dateHelper';
import { Multiselect } from 'react-widgets';
import DiscountCode, { codeTypeToString, DiscountCodeType } from '../../models/discount_code';
import { RootState } from '../../store/reducers/rootReducer';
import SplashScreen from '../SplashScreen';

function DiscountsScreen() {
  const [alsoDeleted, setAlsoDeleted] = useState(false);
  const [searchBar, setSearchBar] = useState("");
  const [codeTypes, setCodeTypes] = useState<{ type: DiscountCodeType }[]>([]);

  useFirestoreConnect([{
    collection: "discountCodes",
    orderBy: [["active", "desc"], ["code", "asc"]],
  }])

  const discountCodes: DiscountCode[] = useSelector((state: RootState) => state.firestore.ordered.discountCodes);

  if (!isLoaded(discountCodes))
    return <SplashScreen />;

  let filteredCodes = discountCodes.filter((c) => (alsoDeleted || c.deleted !== true) && c.code.toLowerCase().includes(searchBar.toLowerCase().replace(/\s+/g, '')));

  filteredCodes.sort((c1, c2) => c1.deleted === true ? -1 : c2.deleted === true ? 1 : 0);

  if (codeTypes.length > 0) {
    let codeTypesLiteral = codeTypes.map((c) => c.type);
    filteredCodes = filteredCodes.filter((c) => codeTypesLiteral.includes(c.type));
  }

  filteredCodes = filteredCodes.filter((c) => c.type !== "SPECIAL");

  const promoDiscounts = filteredCodes.filter((d) => d.isPromo === true);
  const nonPromoDiscounts = filteredCodes.filter(d => d.isPromo !== true);

  return <div className="container my-4">
    <div className="d-flex justify-content-between flex-wrap align-items-center">
      <h4>Sconti</h4>
      <Link to="/discounts/new" className="btn btn-outline-primary">Crea un nuovo sconto</Link>
    </div>

    <div className="d-flex justify-content-between flex-wrap align-items-center">
      <div className="form-inline">
        <div className="form-group">
          <label htmlFor="searchBar">Cerca codice sconto</label>
          <input type="text" id="searchBar" className="form-control mx-sm-3" value={searchBar} onChange={(e) => setSearchBar(e.target.value)} />
        </div>
      </div>
      <div className="custom-control custom-checkbox my-2">
        <input type="checkbox" className="custom-control-input" id="alsoDeleted" checked={alsoDeleted} onChange={(e) => setAlsoDeleted(e.target.checked)} />
        <label className="custom-control-label" htmlFor="alsoDeleted">Mostra anche gli sconti eliminati</label>
      </div>
    </div>

    <div className="d-flex align-items-center mt-2">
      <label className="required mr-3 my-0">Tipo di sconto</label>
      <Multiselect
        valueField='type'
        textField='name'
        data={[
          { type: "ALL_CUSTOMERS", name: codeTypeToString("ALL_CUSTOMERS") },
          { type: "SINGLE_CUSTOMER", name: codeTypeToString("SINGLE_CUSTOMER") },
          { type: "SINGLE_SUPPLIER", name: codeTypeToString("SINGLE_SUPPLIER") },
          { type: "SINGLE_AREA", name: codeTypeToString("SINGLE_AREA") },
          { type: "REWARD", name: codeTypeToString("REWARD") },
          { type: "BRING_A_FRIEND", name: codeTypeToString("BRING_A_FRIEND") },
        ]}
        value={codeTypes}
        onChange={setCodeTypes}
        messages={{
          emptyList: 'Non ci sono altri tipi di sconto disponibili',
          noneSelected: "Tutti gli sconti"
        }}
      />
    </div>

    <div className="mt-4">
      {
        filteredCodes.length === 0
        && "Nessun codice sconto corrispondente ai criteri di ricerca."
      }
    </div>

    {
      promoDiscounts.length > 0 && <>
        <b>Promozioni</b>
        {promoDiscounts.map((discount) =>
          <DiscountView discount={discount} key={discount.id} />
        )}
        <div style={{ height: "20px" }}></div>
      </>
    }

    {
      nonPromoDiscounts.length > 0 && <>
        <b>Altri sconti</b>
        {nonPromoDiscounts.map((discount) =>
          <DiscountView discount={discount} key={discount.id} />
        )}
        <div style={{ height: "20px" }}></div>
      </>
    }

  </div>
}

function DiscountView({ discount }: { discount: DiscountCode }) {
  let stateWidget;
  if (discount.deleted === true) {
    stateWidget = <div><i className="fas fa-circle text-danger fa-xs" /> Eliminato</div>;
  }
  else if (discount.active === true) {
    stateWidget = <div><i className="fas fa-circle text-success fa-xs" /> Abilitato</div>;
  }
  else {
    stateWidget = <div><i className="fas fa-circle text-warning fa-xs" /> Disabilitato</div>;
  }
  return <div className="card card-body py-2 mt-2"  >
    <Link to={`/discounts/${discount.id}`} className="card-link">
      <div className="d-flex justify-content-between flex-wrap">
        <div>
          <div className="small" style={{ color: "grey" }}>{discount.isPromo === true && <b>Promozione: </b>}{codeTypeToString(discount.type)}</div>
          <div>Codice {discount.code}</div>
          <div className="small" style={{ color: "grey" }}>
            {
              discount.type !== "REWARD" && discount.type !== "BRING_A_FRIEND" &&
              <>Valido dal {getDateString(discount.startDate!)}{discount.endDate ? (" al " + getDateString(discount.endDate)) : ""}</>
            }
            {
              discount.type === "BRING_A_FRIEND" &&
              <>{discount.bringAFriendData!.status === "PENDING" ? "Non ancora utilizzabile" : "Attivo"}</>
            }
          </div>
        </div>
        {stateWidget}
      </div>
    </Link>
  </div>
}

export default DiscountsScreen;