
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { toastr } from "react-redux-toastr";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import ButtonWithConfirmation from "../../components/ButtonWithConfirmation";
import { getDateString } from "../../helpers/dateHelper";
import Admin from "../../models/admin";
import Driver from "../../models/driver";
import DriverReport from "../../models/driver_report";
import Supplier from "../../models/supplier";
import SupplierReport from "../../models/supplier_report";
import { deleteDriverReport, deleteSupplierReport } from "../../services/firestore_service";
import { RootState } from "../../store/reducers/rootReducer";
import SplashScreen from "../SplashScreen";
import ReportCard from "./components/ReportCard";

type ReportDetailsScreenProps = RouteComponentProps<{ id: string, reportId: string }, any, any> & {
  type: "driver" | "supplier"
}

function ReportDetailsScreen(props: ReportDetailsScreenProps) {
  const entity = useSelector((state: RootState) => state.firestore.data[props.type] as Supplier | Driver);
  const report = useSelector((state: RootState) => state.firestore.data.report as DriverReport | SupplierReport)
  const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);
  const [stateLoading, setStateLoading] = useState(false);

  useFirestoreConnect([
    {
      collection: props.type === "driver" ? "drivers" : "suppliers",
      doc: props.match.params.id,
      storeAs: props.type,
    },
    {
      collection: props.type === "driver" ? "driver_reports" : "supplier_reports",
      doc: props.match.params.reportId,
      storeAs: "report",
    },
  ]);

  if (!isLoaded(entity, report, admin))
    return <SplashScreen />;

  if (isEmpty(entity))
    return <div className="container my-4">{props.type === "driver" ? "Fattorino" : "Fornitore"} inesistente.</div>;

  if (isEmpty(report))
    return <div className="container my-4">Resoconto inesistente.</div>;

  if (!admin.isMainAdmin && !admin.areaIds!.includes(entity.areaId))
    return <div className="container my-4">Non hai accesso alla visualizzazione di questo {props.type === "driver" ? "fattorino" : "fornitore"}.</div>;

  const basePath = "/" + props.type + "s/" + props.match.params.id + "/";
  const driver = entity as Driver;
  const supplier = entity as Supplier;

  return <div className="container my-4">
    <div className="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h2>Resoconto {getDateString(report.datetime)}</h2>
        <h4>{props.type === "driver" ? ("Fattorino " + driver.nominative) : ("Fornitore " + supplier.name)}</h4>
      </div>

      <div>
        <Link to={basePath + "reports/list"} className="btn btn-outline-secondary mb-2">Visualizza resoconti</Link>
        <div className="d-flex justify-content-end">
          <ButtonWithConfirmation modalTitle="Elimina resoconto"
            modalMessage="Sicuro di voler eliminare questo resoconto? L'azione è irreversibile"
            buttonText="Elimina"
            disabled={stateLoading}
            onConfirm={async () => {
              setStateLoading(true);
              try {
                if (props.type === "driver")
                  await deleteDriverReport(props.match.params.reportId);
                else
                  await deleteSupplierReport(props.match.params.reportId);
                toastr.success("", "Resoconto eliminato con successo!");
                props.history.push(basePath + "reports/list");
              }
              catch (err) {
                console.log(err);
                toastr.error("", "Errore inaspettato! Riprova più tardi.");

                setStateLoading(true);
              }
            }}
            className="btn btn-outline-danger ml-2" />
        </div>
      </div>

    </div>
    <ReportCard amounts={report} showCash={props.type === "driver" || supplier.canUseOwnDrivers === true || supplier.responsibleForCash === true} useCashToPay={report.useCashToPay} type={props.type} />
  </div>
}

export default withRouter(ReportDetailsScreen);