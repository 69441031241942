import { toastr } from "react-redux-toastr";

export const updateOrderState = (orderId, newState) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const functions = firebase.functions();

    dispatch({ type: 'UPDATE_STATE_LOADING' });

    try {
      const updateOrderState = functions.httpsCallable("updateOrderStateAsAdmin");
      const response = await updateOrderState({ orderId, newState });
      if (response.data.success === false) {
        toastr.error("Errore nell'aggiornamento: " + response.data.error)
      }
      else {
        toastr.success("Stato dell'ordine aggiornato con successo!")
      }
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato nell'aggiornamento dello stato!");
    }
    finally {
      dispatch({ type: 'UPDATE_STATE_DONE' });
    }
  }
};