import React, { Component } from 'react'

class SingleImageUploader extends Component {
  constructor(props) {
    super(props);
    if (props.defaultImage) {
      this.state = {
        imagePreviewLink: props.defaultImage
      }
    }
    else
      this.state = {
        imagePreviewLink: "",
      }
  }

  handleImageSelect = (imageFile) => {
    if (imageFile !== undefined) {
      this.props.onImageChange(imageFile);

      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          imagePreviewLink: e.target.result,
        });
      }
      reader.readAsDataURL(imageFile);
    }
  }

  handleImageDelete = () => {
    this.props.onImageChange(null);
    this.setState({
      imagePreviewLink: "",
    });
  }

  render() {
    const { required } = this.props;
    return (
      <div className="form-group">
        <label className={required === true ? "required" : ""} htmlFor="imageSelect">{this.props.title}</label>
        <div id="imagePreview" className={"imagePreview position-relative my-2 " + (this.state.imagePreviewLink !== "" ? "d-block" : "d-none")}>
          {this.props.hideDeleteButton !== true &&
            <div className="deleteImage" onClick={this.handleImageDelete}>
              <i aria-hidden="true" className="fa fa-times" />
            </div>}
          <img src={this.state.imagePreviewLink} style={{
            maxHeight: 300, maxWidth: "100%",
            boxShadow: "0 0 8px 2px rgba(0, 0, 0, 0.1)"
          }} />
        </div>
        <div className="custom-file">
          <input type="file" className="custom-file-input" id="imageSelect" lang="it" required={this.required === true} onChange={(e) => this.handleImageSelect(e.target.files[0])} accept="image/png, image/jpeg" onClick={(event) => { event.target.value = null }} />
          <label className="custom-file-label" htmlFor="imageSelect">Scegli un&apos;immagine</label>
        </div>
      </div>
    );
  }

}

export default SingleImageUploader;