import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import NumberFormat from 'react-number-format'

type ResetAmountModalProps = {
  show: boolean;
  onCancel: () => void;
  onSave: (amount: number) => void;
  driverCash?: boolean;
}

function ResetAmountModal({ show, onCancel, onSave, driverCash = false }: ResetAmountModalProps) {
  const [amount, setAmount] = useState<number | undefined>(0);
  return (
    <Modal show={show} onHide={onCancel}>
      <form onSubmit={(e) => {
        e.preventDefault();
        onSave(amount!);
        setAmount(0);
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{driverCash === true ? "Reimpostazione dei soldi alla mano" : "Reimpostazione del saldo"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="required">Nuovo ammontare</label>
            <NumberFormat
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              prefix="€"
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue === undefined || floatValue >= 0;
              }}
              fixedDecimalScale={true}
              className="form-control"
              value={amount}
              onValueChange={({ floatValue }) => {
                if (floatValue === amount)
                  return;
                setAmount(floatValue);
              }} required />
          </div>
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="confirm" required />
            <label className="custom-control-label" htmlFor="confirm">Sono consapevole di quello che sto facendo.</label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={onCancel}>
            Annulla
          </Button>
          <Button variant="success" type="submit">
            Conferma
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}


export default ResetAmountModal;
