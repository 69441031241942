import React from 'react'
import Order from '../../models/order';
import OrderStates from '../../models/order_states';

export default function OrderStatus({ order }: { order: Order }): JSX.Element {
  if ((order.state === "ACCEPTED" || order.state === "READY") && order.moreProductsRequested)
    return <div><i className="fas fa-circle text-warning fa-xs" /> Richiesta di aggiunte</div>

  switch (order.state) {
    case "NEW":
      if (order.postponeRequested === true && order.isPreponed === true)
        return <div><i className="fas fa-circle text-secondary fa-xs" /> Anticipato</div>
      if (order.postponeRequested === true && order.isPreponed !== true)
        return <div><i className="fas fa-circle text-secondary fa-xs" /> Posticipato</div>
      return <div><i className="fas fa-circle text-success fa-xs" /> Nuovo ordine</div>;
    case "ACCEPTED":
      return <div><i className="fas fa-circle text-primary fa-xs" /> In preparazione</div>;
    case "CANCELLED":
      return <div><i className="fas fa-circle text-danger fa-xs" /> Annullato</div>
    case "READY":
      return <div><i className="fas fa-circle text-success fa-xs" /> Pronto</div>
    case "PICKED_UP":
      return <div><i className="fas fa-circle text-secondary fa-xs" /> In consegna</div>
    case "DELIVERED":
      return <div><i className="fas fa-circle text-info fa-xs" /> Consegnato</div>
    case "REFUSED":
      return <div><i className="fas fa-circle text-danger fa-xs" /> Rifiutato</div>
  }
  return <></>;
}