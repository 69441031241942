import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from './config/fbConfig'
import 'firebase/functions'
import ReduxToastr from 'react-redux-toastr'
import moment from 'moment';
import 'moment/locale/it';
import Geocode from "react-geocode";
import store from './store/store'


const rrfConfig = {
    userProfile: 'users',
    enableClaims: true,
    useFirestoreForProfile: true
}


const rrfProps = {
    firebase: firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance: createFirestoreInstance
}


Geocode.setApiKey("AIzaSyA7-StQ6t1T2XETxrQhv2MzwsxpepWckas");

Geocode.setLanguage("it");

Geocode.setRegion("it");

moment.locale("it");

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <App />
            <ReduxToastr timeOut={4000}
                newestOnTop={false}
                preventDuplicates={true}
                position={"top-right"}
                transitionIn={"fadeIn"}
                transitionOut={"fadeOut"}
            />
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root'));

serviceWorker.register({
    onUpdate: (reg: any) => store.dispatch({ type: "SERVICE_WORKER_UPDATE", payload: reg })
});