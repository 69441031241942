
function doubleToCurrency(price: number): string {
  return price.toFixed(2) + "€";
}

function intToCurrency(price: number): string {
  return (price / 100.0).toFixed(2) + "€";
}

function intToCurrencyWithSign(price: number): string {
  return (price > 0 ? "+" : "") + (price / 100.0).toFixed(2) + "€";
}

function doubleToPercentage(percentage: number): string {
  return (percentage * 100).toFixed(2) + "%";
}

export { doubleToCurrency, doubleToPercentage, intToCurrency, intToCurrencyWithSign };